import { Box, Button, Divider, Typography } from '@mui/material';

import confirmIcon from 'assets/confirm.svg';

const AssignedLoginContent = () => {
  const CONFIRMED_INFO = [
    {
      title: 'Full Name',
      info: 'Adedamola Adewale',
    },
    {
      title: 'Phone',
      info: '+234 894 4893 903',
    },
    {
      title: 'Park Name',
      info: 'Young Legacy',
    },
    {
      title: 'Address',
      info: '32, Abeokuta Road',
    },
    {
      title: 'Bus-Stop',
      info: 'Iyana-Ipaja',
    },
    {
      title: 'State',
      info: 'Lagos',
    },
    {
      title: 'Park ID',
      info: 'LGA-898 IP3',
    },
  ];
  return (
    <>
      <Box
        height={150}
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={{ backgroundColor: '#E8FFF2' }}
      >
        <img src={confirmIcon} alt='' width={110.45} height={107.69} />
      </Box>
      <Box p={2}>
        <Typography
          py={1}
          variant='body2'
          fontSize={22}
          textAlign='left'
          fontWeight={700}
        >
          You have been assigned. Confirmed your park ID
        </Typography>
        <Box mb={3}>
          {CONFIRMED_INFO.map((item) => (
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 1,
                  my: 1,
                }}
              >
                <Typography fontSize={12}>{item.title}</Typography>
                <Typography fontSize={12} fontWeight={600}>
                  {item.info}
                </Typography>
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
        <Box mt={1}>
          <Button fullWidth sx={{ borderRadius: '100px' }} variant='contained'>
            Accept
          </Button>
        </Box>
        <Box mt={1}>
          <Button
            fullWidth
            sx={{
              borderRadius: '100px',
              bgcolor: '#F2F4F7',
              color: '#475467',
            }}
            variant='contained'
          >
            Decline
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AssignedLoginContent;
