import { Box, Typography } from "@mui/material";

import carImg from "assets/vehicle-car.png";

const VehicleCard = ({
  onClick,
  license_plate,
  car_type,
  front_photo,
}: any) => {
  return (
    <Box
      border="1px solid #E4E7EC"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={0.5}
      p={2}
      boxShadow="0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A"
      mt={1}
      borderRadius="8px"
      width="100%"
      sx={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <Box width="30%">
        <img width="74px" src={carImg || front_photo} alt="car-img" />
      </Box>
      <Box width="70%">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography color="#667085" fontSize="10px">
              Vehicle
            </Typography>
            <Typography fontWeight={600} color="#101828" fontSize="12px">
              {car_type}
            </Typography>
          </Box>
          <Box>
            <Typography color="#667085" fontSize="10px">
              License plate
            </Typography>
            <Typography fontWeight={600} color="#101828" fontSize="12px">
              {license_plate}
            </Typography>
          </Box>
        </Box>
        <Box
          mt={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography color="#667085" fontSize="10px">
              Driver
            </Typography>
            <Typography fontWeight={600} color="#101828" fontSize="12px">
              Mr Adetola
            </Typography>
          </Box>
          <Box textAlign="right">
            <Typography color="#667085" fontSize="10px">
              Phone
            </Typography>
            <Typography fontWeight={600} color="#101828" fontSize="12px">
              081078493792
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VehicleCard;
