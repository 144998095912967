import ArrowBack from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import {
  Avatar,
  Card,
  CircularProgress,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import DashboardLayout from './../layouts/DashboardLayout';
import { useNavigate } from 'react-router-dom';
import MessageIcon from '../assets/messageIcon.svg';
import notificationIcon from '../assets/notificationIcon.svg';
import Messages from './Messages';

import { useQuery } from '@tanstack/react-query';
import { getAllRooms, getNotifications } from 'services/activities';
import { getUserProfile } from 'services/auth';
import { formatDate } from 'utils/misc';

const Activities = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState('mssg');
  const [message, setMessage] = React.useState(false);
  const [notification, setNotification] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [showFloatingMessage, setShowFloatingMessage] = React.useState(false);

  const { data: dtQu, isLoading: isUserProfileLoading } = useQuery({
    queryKey: ['GET_USER_PROFILE'],
    queryFn: getUserProfile,
  });

  const { data, isLoading } = useQuery({
    queryKey: ['GET_ACTIVITIES'],
    queryFn: getNotifications,
  });

  console.log('Notifications', data?.data?.notification);

  const { data: rooms, isLoading: isRoomsLoading } = useQuery({
    queryKey: ['GET_ROOMS'],
    queryFn: getAllRooms,
  });

  const userRooms = rooms?.data?.rooms;
  const notificationData = data?.data?.notification;

  const handleChange = (value: string) => {
    setValue(value);
  };
  const navigate = useNavigate();

  if (isRoomsLoading || isLoading) {
    return (
      <Box
        width='100%'
        display='flex'
        height='100vh'
        alignItems='center'
        justifyContent='center'
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <DashboardLayout>
      <Box p={2} mx={{ md: '14%' }}>
        <Box mb={1}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              gap: 2,
            }}
          >
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={700} fontSize={20}>
              Activities
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Box
            border='1px solid #E4E7EC'
            bgcolor='#F9FAFB'
            borderRadius='8px'
            display='grid'
            gridTemplateColumns='1fr 1fr'
            sx={{ placeItems: 'center' }}
            mb={2}
            width={{ xs: '100%', md: '342px' }}
          >
            <Typography
              sx={{
                border: value === 'mssg' ? '1px solid #E4E7EC' : '',
                bgcolor: value === 'mssg' ? 'white' : '#F9FAFB',
                borderRadius: '8px',
                p: value === 'mssg' ? '8px 12px' : '',
                width: value === 'mssg' ? '100%' : '',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
              }}
              fontWeight={600}
              fontSize={14}
              onClick={() => handleChange('mssg')}
            >
              Messages
            </Typography>
            <Typography
              sx={{
                border: value === 'notify' ? '1px solid #E4E7EC' : '1px',
                bgcolor: value === 'notify' ? 'white' : '#F9FAFB',
                borderRadius: '8px',
                p: value === 'notify' ? '8px 12px' : '',
                width: value === 'notify' ? '100%' : '',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
              }}
              fontWeight={600}
              fontSize={14}
              onClick={() => handleChange('notify')}
            >
              Notifications*
            </Typography>
          </Box>
          <Box>
            {value === 'mssg' && (
              <Box>
                {!message ? (
                  <Box>
                    {userRooms.map((item: any) => (
                      <MessageCard
                        {...item}
                        handleClick={() => {
                          navigate('/messages/' + item?.room_id);
                        }}
                      />
                    ))}
                  </Box>
                ) : (
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    height={{ xs: '60vh', md: '50vh' }}
                    flexDirection='column'
                    gap='12px'
                  >
                    <img
                      src={MessageIcon}
                      alt='message-icon'
                      height='48px'
                      width='48px'
                    />
                    <Typography fontSize='12px' fontWeight={600}>
                      No active message as the moment
                    </Typography>
                    <Typography fontSize='12px' fontWeight={400}>
                      Messages appear usually during a live trip
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
            {value === 'notify' && (
              <Box>
                {notificationData?.length !== 0 ? (
                  <Box>
                    {notificationData.map((d: any, i: number) => (
                      <NotificationCard data={d} key={i} />
                    ))}
                  </Box>
                ) : (
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    height={{ xs: '60vh', md: '50vh' }}
                    flexDirection='column'
                    gap='12px'
                  >
                    <img
                      src={notificationIcon}
                      alt='notification-icon'
                      height='48px'
                      width='48px'
                    />
                    <Typography fontSize='12px' fontWeight={600}>
                      No new notification at the moment
                    </Typography>
                    <Typography fontSize='12px' fontWeight={400}>
                      New notifications will appear here
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        position='absolute'
        bottom='-5%'
        right='5%'
        display={showFloatingMessage ? 'block' : 'none'}
        bgcolor='#FFFFFF'
        width='390px'
        height='581px'
        border='1px solid #ECE4E7'
        borderRadius='10px'
        overflow='hidden'
      >
        <Messages />
      </Box>
    </DashboardLayout>
  );
};

export default Activities;

const MessageCard = ({
  handleClick,
  participant,
  lastmessage,
  room_id,
}: {
  handleClick: () => void;
  participant?: {
    firstName: string;
    lastName: string;
  };
  lastmessage: { message?: string };
  room_id: string;
}) => {
  return (
    <Card
      sx={{
        p: 1,
        border: 1,
        borderColor: 'divider',
        width: '100%',
        borderRadius: 2,
        display: 'flex',
        justifyContent: { xs: 'space-between', md: 'normal' },
        alignItems: 'start',
        gap: 1,
        mb: 1,
        cursor: 'pointer',
      }}
      elevation={0}
      onClick={handleClick}
    >
      <Avatar>{participant?.firstName[0]}</Avatar>
      <Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          mb={1}
        >
          <Typography fontSize={12} fontWeight={600}>
            {participant?.firstName} {participant?.lastName} sent a new message
          </Typography>
          <Typography fontSize={12} color='#98A2B3'>
            18h
          </Typography>
        </Box>
        <Typography fontSize={12} borderLeft={1} pl={0.5} color='#98A2B3'>
          {lastmessage?.message || ''}
        </Typography>
      </Box>
    </Card>
  );
};

const NotificationCard = ({ data }: { data: any }) => {
  return (
    <Card
      sx={{
        p: 1,
        border: 1,
        borderColor: 'divider',
        width: '100%',
        borderRadius: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        gap: 1,
        mb: 1,
      }}
      elevation={0}
    >
      <Box width='100%'>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          width='100%'
          mb={1}
        >
          <Typography fontSize={12} fontWeight={600}>
            {data.title}
          </Typography>
          <Typography fontSize={12} color='#98A2B3'>
            {formatDate(data.timestamp)}
          </Typography>
        </Box>
        <Typography fontSize={12} pl={0.5} color='#98A2B3'>
          {data.message}
        </Typography>
      </Box>
    </Card>
  );
};
