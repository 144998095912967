import { useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';

interface RoomProps {
  roomId: string;
  participant_1: string;
  participant_2: string;
  trip_id: string;
}

interface JoinRoomProps {
  roomId: string;
}

interface MessageProps {
  roomId: string;
  message: string;
}

export const createRoom = ({
  roomId,
  participant_1,
  participant_2,
  trip_id,
}: RoomProps) => ({
  event: 'createRoom',
  roomId,
  participant_1,
  participant_2,
  trip_id,
});

export const fetchMessages = ({ roomId }: { roomId: string }) => ({
  event: 'roomMessages',
  roomId,
});

export const joinRoom = ({ roomId }: JoinRoomProps) => ({
  event: 'joinRoom',
  roomId,
});

export const sendMessage = ({ roomId, message }: MessageProps) => ({
  event: 'sendMessage',
  roomId,
  message,
});

interface UseSocketProps {
  [key: string]: (data: any) => void;
}

const useSocket = (events: UseSocketProps = {}) => {
  const SOCKET_URL = 'https://www.staging.move9ja.com';
  const [isConnected, setIsConnected] = useState(false);
  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(SOCKET_URL, {
        auth: {
          authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        transports: ['websocket'],
      });
    }

    const socket = socketRef.current;

    socket.on('connect', () => {
      console.log('Connected to the server');
      setIsConnected(true);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from the server');
      setIsConnected(false);
    });

    socket.on('connect_error', (err) => {
      console.error('Connection error: ', err.message);
    });

    // Attach event listeners from props
    Object.keys(events).forEach((event) => {
      socket.on(event, events[event]);
    });

    return () => {
      Object.keys(events).forEach((event) => {
        socket.off(event);
      });
    };
  }, [events]);

  // Emitters for socket events with correct typings
  const emitCreateRoom = (data: RoomProps) => {
    const roomData = createRoom(data);
    socketRef.current?.emit(roomData.event, roomData);
  };

  const emitJoinRoom = (data: JoinRoomProps) => {
    const joinData = joinRoom(data);
    socketRef.current?.emit(joinData.event, joinData);
  };

  const emitSendMessage = (data: MessageProps, callback?: (r: any) => void) => {
    const messageData = sendMessage(data);
    socketRef.current?.emit(messageData.event, messageData, (response: any) => {
      console.log('Emit response', response);
      callback?.(response);
    });
  };

  const emitFetchMessages = (roomId: string) => {
    const messageData = fetchMessages({ roomId });
    socketRef.current?.emit(messageData.event, messageData);
  };

  return {
    isConnected,
    emitCreateRoom,
    emitJoinRoom,
    emitSendMessage,
    emitFetchMessages,
    socket: socketRef?.current,
  };
};

export default useSocket;
