import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AddVehicle } from './AddVehicle';
import AddNewDriver from './AddNewDriver';
import { Button, Typography } from '@mui/material';

const AddNewDV = () => {
  const [value, setValue] = React.useState('vehicle');

  const handleChange = (value: string) => {
    setValue(value);
  };
  const navigate = useNavigate();
  return (
    <Box sx={{ width: '100%', typography: 'body1' }} p={2}>
      <Box mb={2}>
        {value === 'vehicle' && (
          <Typography fontWeight={600}>Add New Vehicle</Typography>
        )}
        {value === 'driver' && (
          <Typography fontWeight={600}>Add New Driver</Typography>
        )}
      </Box>

      <Typography
        sx={{
          border: value === 'vehicle' ? '1px solid #E4E7EC' : '',
          bgcolor: value === 'vehicle' ? 'white' : '#F9FAFB',
          borderRadius: '8px',
          p: value === 'vehicle' ? '8px 12px' : '',
          width: value === 'vehicle' ? '100%' : '',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          whiteSpace: 'nowrap',
        }}
        fontWeight={600}
        fontSize={14}
        onClick={() => handleChange('vehicle')}
      >
        New vehicle
      </Typography>
      {/* <Typography
          sx={{
            border: value === 'driver' ? '1px solid #E4E7EC' : '1px',
            bgcolor: value === 'driver' ? 'white' : '#F9FAFB',
            borderRadius: '8px',
            p: value === 'driver' ? '8px 12px' : '',
            width: value === 'driver' ? '100%' : '',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            whiteSpace: 'nowrap',
          }}
          fontWeight={600}
          fontSize={14}
          onClick={() => handleChange('driver')}
        >
          New driver
        </Typography> */}

      <Box>
        {value === 'vehicle' && <AddVehicle />}
        {value === 'driver' && <AddNewDriver />}
      </Box>
    </Box>
  );
};

export default AddNewDV;
