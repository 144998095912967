import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import DashboardLayout from 'layouts/DashboardLayout';
import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import TripCard from 'shared/TripCard';
import CustomTimeline from 'shared/TravelLocationTimeline';
import BottomDrawer from 'shared/BottomDrawer';
import TripOrderContent, { ReUseAbleTripCards } from 'shared/TripOrderContent';

import useBookingTrips from 'hooks/useTrips';
import useTrips from 'hooks/useTrips';

export function calculateTimeDifference(
  startTime: string,
  endTime: string
): string {
  const start = convertToMinutes(startTime);
  const end = convertToMinutes(endTime);

  // Adjust for time spanning over midnight
  let difference = end - start;
  if (difference < 0) {
    difference += 24 * 60; // Add 24 hours in minutes to adjust for next day
  }

  const hours = Math.floor(difference / 60);
  const minutes = difference % 60;

  return `${hours}h ${minutes}m`;
}

function convertToMinutes(time: string): number {
  const [timePart, modifier] = time.split(/([AP]M)/i);
  let [hours, minutes] = timePart.split(':').map(Number);

  if (modifier.toUpperCase() === 'PM' && hours !== 12) {
    hours += 12;
  } else if (modifier.toUpperCase() === 'AM' && hours === 12) {
    hours = 0; // Adjust for midnight
  }

  return hours * 60 + minutes;
}

const Trips = () => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [selectedTrip, setSelectedTrip] = React.useState(null);

  const { data, isLoading } = useTrips();

  const trips = data?.data?.ongoing || [];
  const ongoingtrips = data?.data?.completed || [];

  if (isLoading) {
    return (
      <Box
        display='grid'
        height='100vh'
        width='100%'
        sx={{ placeContent: 'center' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <DashboardLayout>
      <BottomDrawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        endText={
          <Typography fontSize='14px' color='#3E7EFF'>
            Share
          </Typography>
        }
      >
        <TripOrderContent tripData={selectedTrip} />
      </BottomDrawer>
      <Box p={2} mx={{ md: '14%' }}>
        <Box display='flex' alignItems='center'>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography ml={1} fontSize='20px' fontWeight={700}>
            Trips
          </Typography>
        </Box>
        <Box
          display={{ xs: 'block', md: 'grid' }}
          sx={{ gridTemplateColumns: '45% 55%', gap: '12px' }}
        >
          {trips?.length === 0 && (
            <ReUseAbleTripCards bgcolor='#379e66' color='#fff' title='Ongoing'>
              <Typography p={2}>No On going trips</Typography>
            </ReUseAbleTripCards>
          )}

          {trips.map((trip: any) => (
            <ReUseAbleTripCards
              key={trip.trip_id}
              title='Ongoing'
              bgcolor='#379E66'
              color='#fff'
              subtitle={calculateTimeDifference(trip.starttime, trip.endtime)}
              onClick={() => {
                setSelectedTrip({
                  ...trip,
                  status: 'Completed',
                  completed: true,
                });
                setOpen(true);
              }}
            >
              <CustomTimeline
                from={trip.from}
                to={trip.to}
                start_park={trip.parkbus_stop}
                start_time={trip.starttime}
                stop_park={trip.stop_park}
                stop_time={trip.endtime}
              />
            </ReUseAbleTripCards>
          ))}
          <ReUseAbleTripCards title='History' subtitle='View all'>
            {ongoingtrips?.map((trip: any) => (
              <TripCard
                onClick={() => {
                  setSelectedTrip({
                    ...trip,
                    status: 'Enroute',
                    completed: true,
                  });
                  setOpen(true);
                }}
                from={trip.from}
                to={trip.to}
                start={trip.parkbus_stop}
                // start_time={trip.starttime}
                stop={trip.stop_park}
                status='Ongoing'
                //stop_time={trip.endtime}
              />
            ))}
            {ongoingtrips?.length === 0 && (
              <Typography
                display='grid'
                sx={{ placeContent: 'center' }}
                m={5}
                fontSize={12}
              >
                No booking available here yet
              </Typography>
            )}
          </ReUseAbleTripCards>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Trips;
