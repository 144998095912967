import { ArrowBack } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllbanks, verifyBankAccount } from 'services/bank';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from 'react';
import { handleMutationError } from 'utils/handler';
import { completeRegistration } from 'services/auth';
import toast from 'react-hot-toast';
import move9jaLogo from '../../assets/move9ja.svg';

// Validation schema
const schema = yup.object().shape({
  // bank_name: yup
  //   .object()
  //   .shape({
  //     name: yup.string().required("Bank name is required"),
  //   })
  //   .nullable()
  //   .required("Bank name is required"),
  bank_name: yup.string().required('Bank name is required'),
  bank_account: yup
    .string()
    .matches(/^\d{10}$/, 'Bank account must be exactly 10 digits')
    .required('Bank account is required'),
  name: yup.string().required('Account name is required'),
});

export const AddBank = () => {
  const navigate = useNavigate();
  const userLocation = useLocation();
  const { userId } = userLocation.state || {};

  const { data } = useQuery({
    queryKey: ['GET_ALL_BANKS'],
    queryFn: getAllbanks,
  });

  const mutation = useMutation({
    mutationFn: verifyBankAccount,
    onSuccess: ({ data }) => {},
    onError: (err) => {
      handleMutationError(err);
    },
  });

  const addBankMutation = useMutation({
    mutationFn: async (data: any) => {
      if (userId) {
        completeRegistration({ ...data, user_id: userId });
      }
    },
    onSuccess: (data) => {
      toast.success('Bank added successfully');
      navigate('/profile-completed');
    },
    onError: (err) => {
      handleMutationError(err);
      console.log(err);
    },
  });

  const resp = mutation.data;

  const location = useLocation();

  const banks = data?.data?.banks || [];

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const bankAccount = watch('bank_account');
  const bankName = watch('bank_name');

  const submitData = {
    user_id: location?.state?.userId,
    name: resp?.data?.result?.name,
    account_number: bankAccount,
    bank_name: banks?.find((x: any) => x?.code === bankName)?.name,
  };

  useEffect(() => {
    if (bankAccount?.length === 10 && bankName) {
      mutation.mutate({ code: bankName, account_number: bankAccount });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccount, bankName]);

  const onSubmit = () => {
    addBankMutation.mutate(submitData);
  };

  return (
    <Box height='100vh'>
      <Box
        height='72px'
        p='0px 80px'
        display={{ xs: 'none', md: 'flex' }}
        alignItems='center'
        justifyContent='space-between'
        border='1px solid #E4E7EC'
      >
        <img src={move9jaLogo} alt='' width='162.75px' height='50.26px' />
        <Button
          sx={{
            bgcolor: '#DA4733',
            width: '109px',
            borderRadius: '8px',
            fontWeight: 600,
            fontSize: '14px',
          }}
          variant='contained'
        >
          Register
        </Button>
      </Box>
      <Box
        sx={{
          display: 'grid',
          mx: 'auto',
        }}
      >
        <Box
          display='flex'
          height={{ md: '100vh' }}
          alignItems='center'
          flexDirection='column'
        >
          <Box
            width={{ xs: '100%', md: '510px' }}
            p='0px 24px'
            mt={{ md: '40px', xs: '40px' }}
          >
            <>
              {mutation.isPending && (
                <>
                  <Box
                    position='absolute'
                    top={0}
                    sx={{
                      height: '100vh',
                      width: '100%',
                      backgroundColor: 'transparent',
                      backgroundPosition: 'no-repeat',
                      backgroundSize: 'cover',
                      backdropFilter: 'blur(2px)',
                    }}
                  />
                  <Box
                    position='absolute'
                    top='50%'
                    left='50%'
                    gap={1}
                    sx={{ zIndex: 2, transform: 'translate(-50%, -50%)' }}
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    flexDirection='column'
                  >
                    <CircularProgress />
                    <Typography fontSize={12} fontWeight={600}>
                      Confirming your details
                    </Typography>
                    <Typography fontSize={12}>Loading...</Typography>
                  </Box>
                </>
              )}
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    gap: 2,
                  }}
                  mb={1}
                >
                  <IconButton
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <ArrowBack />
                  </IconButton>
                  <Typography fontWeight={800} fontSize={18}>
                    Add Bank
                  </Typography>
                </Box>
                <Box>
                  <Typography mb={4}>
                    Add payout details for your withdrawal
                  </Typography>
                  <form
                    noValidate
                    autoComplete='off'
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <FormControl variant='standard' fullWidth>
                      <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                        Bank Name
                      </InputLabel>
                      <Controller
                        name='bank_name'
                        control={control}
                        defaultValue={undefined}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={banks}
                            getOptionLabel={(option: any) => option.name}
                            isOptionEqualToValue={(option: any, value) =>
                              option.code === value.code
                            }
                            onChange={(_, data) =>
                              field.onChange(data ? data.code : '')
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant='standard'
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                placeholder='Select Bank Name'
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: 'none',
                                      p: 0,
                                    },
                                  },
                                  '& .css-1023f6h-MuiInputBase-root-MuiInput-root::before':
                                    {
                                      borderBottom: '0px solid red',
                                    },
                                  bgcolor: '#F9F9F9',
                                  mt: 3,
                                  px: 2,
                                  py: 1,
                                  borderRadius: 3,
                                  outline: 'none',
                                  border: 'none',
                                }}
                                error={!!errors.bank_name}
                                helperText={
                                  errors.bank_name
                                    ? errors.bank_name.message
                                    : ''
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl sx={{ mt: 2 }} variant='standard' fullWidth>
                      <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                        Bank account
                      </InputLabel>
                      <Controller
                        name='bank_account'
                        control={control}
                        defaultValue=''
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder='Enter your 10 digits bank account number'
                            variant='standard'
                            InputProps={{ disableUnderline: true }}
                            sx={{
                              bgcolor: '#F9F9F9',
                              p: 1,
                              mt: 2,
                              mb: 2,
                              borderRadius: 3,
                              outline: 'none',
                              border: 'none',
                            }}
                            error={!!errors.bank_account}
                            helperText={
                              errors.bank_account
                                ? errors.bank_account.message
                                : ''
                            }
                          />
                        )}
                      />
                    </FormControl>
                    {resp?.data?.result?.name && bankAccount?.length === 10 && (
                      <>
                        <FormControl variant='standard' fullWidth>
                          <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                            Account Name
                          </InputLabel>
                          <TextField
                            disabled
                            value={resp?.data?.result?.name}
                            variant='standard'
                            InputProps={{ disableUnderline: true }}
                            fullWidth
                            sx={{
                              bgcolor: '#F9F9F9',
                              p: 1,
                              mb: 2,
                              borderRadius: 3,
                              outline: 'none',
                              border: 'none',
                            }}
                          />
                        </FormControl>
                      </>
                    )}

                    {bankAccount?.length === 10 && resp?.data?.result?.name && (
                      <Button
                        variant='contained'
                        fullWidth
                        disabled={!!addBankMutation.isPending}
                        // onClick={() => {

                        // }}
                        type='submit'
                        sx={{ fontWeight: 400, p: 1, borderRadius: 2, my: 2 }}
                      >
                        {addBankMutation.isPending ? 'Loading..' : 'Continue'}
                      </Button>
                    )}
                  </form>
                </Box>
              </Box>
            </>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddBank;
