import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material';
import { theme } from 'theme/index';
import { BrowserRouter } from 'react-router-dom';
import AppRoute from 'routes/AppRoute';

import * as serviceWorker from './serviceWorkerRegistration';
import toast, { Toaster } from 'react-hot-toast';
import { toastOption } from 'config/index';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SocketProvider } from 'context/socketProvider';
import { AppProvider as ChatProvider } from 'context/chatProvider';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Toaster toastOptions={toastOption} />
            <SocketProvider>
              <ChatProvider>
                <AppRoute />
              </ChatProvider>
            </SocketProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

const config = {
  onUpdate: (registration: any) => {
    toast.success('App has been updated internally please refresh');
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
  },
  onSuccess: (registration: any) => {
    console.log('Content is cached for offline use.');
  },
};

serviceWorker.register(config);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
