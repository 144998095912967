import { Box, Button, Typography } from "@mui/material";
import bgImg from "assets/driverPic.jpeg";
import { LastPage } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export const Landing = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          background: "url(" + bgImg + ")",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left center",
          height: "100vh",
          position: "fixed",
          width: "100vw",
          margin: 0,
          padding: 0,
          display: "grid",
          placeItems: "end",
        }}
      >
        <Box
          sx={{
            mx: 2,
            mb: 13,
            display: "flex",
            justifyContent: "center",
            gap: 1,
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography fontWeight={600} fontSize={32} color='white'>
            Manage your park for more earn cash.
          </Typography>
          <Button
            variant='contained'
            endIcon={<LastPage />}
            onClick={() => {
              navigate("/login");
            }}
            sx={{
              backgroundColor: "#DA4733",
              width: "100%",
              p: 1.5,
              fontWeight: 500,
              borderRadius: 2,
            }}
          >
            Continue with phone
          </Button>
        </Box>
      </Box>
    </>
  );
};
