import { ArrowBack, Check } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { updateTripStatus } from 'services/booking';

const activeDrivingStage = [
  { value: 'not started' },
  { value: 'enroute' },
  { value: 'completed' },
];

const NotStarted = ({
  onSelectStage,
  bookingTrip,
  onClose,
}: {
  onSelectStage?: (currentStage: string) => void;
  bookingTrip?: any;
  onClose: () => void;
}) => {
  const [selectedStage, setSelectedStage] = useState<string | null>(null);

  const updateStatusMutation = useMutation({
    mutationFn: async (data: any) => {
      return await updateTripStatus(data);
    },
    onSuccess: (data) => {
      toast.success('Status Updated');
      onClose();
    },
    onError: (err) => {
      toast.error('An unknown error has occured');
    },
  });

  const tripId = bookingTrip.trip_id;

  const onSubmitData = (tripId: string, status: string) => {
    const data = {
      trip_id: tripId,
      status: status,
    };

    updateStatusMutation.mutate(data);

    setSelectedStage(status);
    if (onSelectStage) {
      onSelectStage(status);
    }
  };

  return (
    <Box p={2}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <ArrowBack />
        <Close onClick={onClose} sx={{ cursor: 'pointer' }} />
      </Box>

      <Box>
        {updateStatusMutation?.isPending && (
          <Box
            display='flex'
            alignItems='center'
            height='100%'
            width='100%'
            justifyContent='center'
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      <List>
        {!updateStatusMutation?.isPending &&
          activeDrivingStage.map((stage) => (
            <div key={stage.value}>
              <ListItem
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                  py: 2,
                  backgroundColor:
                    selectedStage === stage.value ? '#f0f0f0' : 'inherit',
                }}
                onClick={() => onSubmitData(tripId, stage.value)}
              >
                <Typography sx={{ textTransform: 'capitalize' }}>
                  {stage.value}{' '}
                </Typography>
                {selectedStage === stage.value ? <Check /> : null}
              </ListItem>
              <Divider />
            </div>
          ))}
      </List>
    </Box>
  );
};

export default NotStarted;
