import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

import {
  Timeline,
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Box, Typography } from '@mui/material';
import Move9jaCard from 'shared/Move9jaCard';

const Move9jaTravelTimeline = () => {
  const TIME_LINE = [
    {
      icon: (
        <ShoppingCartOutlinedIcon sx={{ color: 'inherit', fontSize: '15px' }} />
      ),
      date: '2023-08-28 09:00 AM',
      title: 'Bus ticket booked',
      isActive: true,
    },
    {
      icon: <HourglassEmptyIcon sx={{ color: 'inherit', fontSize: '15px' }} />,
      date: '2023-08-28 09:00 AM',
      title: 'Order Processing',
      isActive: true,
    },
    {
      icon: (
        <AccountBalanceWalletOutlinedIcon
          sx={{ color: 'inherit', fontSize: '15px' }}
        />
      ),
      date: '2023-08-28 09:00 AM',
      title: 'Payment Verification',
      isActive: true,
    },
    {
      icon: (
        <Inventory2OutlinedIcon sx={{ color: 'inherit', fontSize: '15px' }} />
      ),
      date: '2023-08-28 09:00 AM',
      title: 'Seat Secured',
      isActive: true,
    },
    {
      icon: (
        <RocketLaunchOutlinedIcon sx={{ color: 'inherit', fontSize: '15px' }} />
      ),
      date: '2023-08-28 09:00 AM',
      title: 'Bus boarded',
      isActive: false,
    },
    {
      icon: (
        <LocalShippingOutlinedIcon
          sx={{ color: 'inherit', fontSize: '15px' }}
        />
      ),
      date: '2023-08-28 09:00 AM',
      title: 'In transit',
      isActive: false,
    },
    {
      icon: (
        <DoneOutlineOutlinedIcon sx={{ color: 'inherit', fontSize: '15px' }} />
      ),
      date: '2023-08-28 09:00 AM',
      title: 'Arrived Destination',
      isActive: false,
    },
  ];
  return (
    <Move9jaCard title='Timeline'>
      <Box p={2}>
        <Timeline
          sx={{
            width: '100%',
            p: 0,
            '.MuiTimelineOppositeContent-root': {
              display: 'none',
            },
            '.MuiTimelineConnector-root': {
              bgcolor: '#fff',
              width: '1px',
              //   border: "1px solid #379E66",
            },
            '.MuiTimelineDot-root': {
              m: 0,
              boxShadow: 'none',
            },
            '.css-bxlj2e-MuiTimelineConnector-root': {
              border: '1px solid red',
              width: '1px',
            },
            '.css-1vxamya-MuiTimelineDot-root': {
              boxShadow: '',
            },
          }}
        >
          {TIME_LINE.map(({ icon, date, title, isActive }, i) => (
            <TimelineItem>
              <TimelineOppositeContent sx={{ flex: 0 }} />
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    bgcolor: isActive ? '#379E66' : '#F2F4F7',
                    boxShadow: ' !important',
                  }}
                >
                  <Box
                    height='28px'
                    width='28px'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    color={isActive ? '#fff' : '#667085'}
                  >
                    {icon}
                  </Box>
                </TimelineDot>
                {i !== TIME_LINE.length - 1 && (
                  <TimelineConnector
                    sx={{
                      border: isActive
                        ? '1px solid #379E66'
                        : '1px solid #F2F4F7',
                      p: 0,
                      m: 0,
                    }}
                  />
                )}
              </TimelineSeparator>
              <TimelineConnector sx={{ bgcolor: '', px: 1, border: 0 }}>
                <Typography
                  sx={{ fontSize: '12px', fontWeight: 400, color: '#667085' }}
                >
                  {date}
                </Typography>
                <Typography
                  sx={{ fontSize: '12px', fontWeight: 600, color: '#101828' }}
                >
                  {title}
                </Typography>
              </TimelineConnector>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
    </Move9jaCard>
  );
};

export default Move9jaTravelTimeline;
