import { ArrowBack, Check } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import BottomDrawer from 'shared/BottomDrawer';
import { EditDriverContact } from 'shared/EditDriverContact';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { keyframes } from '@mui/material';
import driverIcon from '../assets/drivers.png';
import { useQuery } from '@tanstack/react-query';
import { getDriver } from 'services/driver';

const comeIn = keyframes`
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
`;

export const CreateDriver = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [edit, setEdit] = React.useState(true);

  const handleEdit = () => {
    setEdit(!edit);
  };

  const { data, isPending } = useQuery({
    queryFn: getDriver,
    queryKey: ['GET_DRIVERS'],
  });

  console.log('Driver datas', data?.data?.result);

  return (
    <>
      <BottomDrawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <EditDriverContact />
      </BottomDrawer>
      <Box p={3}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            gap: 2,
          }}
          mb={1}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography fontWeight={800} fontSize={20}>
            Create Driver
          </Typography>
        </Box>
        <Box
          border='1px solid #E4E7EC'
          bgcolor='#F6F6F6'
          borderRadius='8px'
          mt={3}
          overflow='hidden'
          height={94}
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='flex-end'
          gap={2}
          p={1}
          onClick={() => {
            setOpen(true);
          }}
          sx={{
            cursor: 'pointer',
          }}
        >
          <img src={driverIcon} alt='' height={32} width={32} />
          <Typography fontSize={12}>+ Create new</Typography>
        </Box>
        <Box
          border='1px solid #E4E7EC'
          borderRadius='8px'
          mt={3}
          overflow='hidden'
        >
          <Box
            bgcolor='#F6F6F6'
            p={1}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography fontSize='12px' fontWeight={600}>
              Saved
            </Typography>
            <Button
              variant='text'
              sx={{
                color: edit ? '#3E7EFF' : 'red',
                fontSize: '12px',
                fontWeight: 600,
              }}
              onClick={handleEdit}
            >
              {edit ? 'Edit' : 'Cancel'}
            </Button>
          </Box>
          <Box>
            {isPending && (
              <Box
                display='flex'
                p={2}
                alignItems='center'
                justifyContent='center'
              >
                <CircularProgress />
              </Box>
            )}
            {data?.data?.result?.length === 0 && (
              <Box
                display='flex'
                alignItems='center'
                p={3}
                justifyContent='center'
              >
                <Typography>No Drivers, Kindly Create</Typography>
              </Box>
            )}
            {data?.data?.result.map((driver: any) => (
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                p={1}
                key={driver._id}
              >
                <Box display='flex' alignItems='center' gap={edit ? 2 : 1}>
                  {edit ? (
                    ''
                  ) : (
                    <RemoveCircleIcon
                      sx={{ color: '#DA4733', height: 30, width: 30 }}
                      fontSize='medium'
                      onClick={() => {}} // this function will remove a driver from the list
                    />
                  )}
                  <Avatar
                    sx={{
                      bgcolor: '#98A2B3',
                      height: 40,
                      width: 40,
                      textTransform: 'uppercase',
                    }}
                  >
                    {driver.name.slice(0, 1)}
                  </Avatar>

                  <Box>
                    <Typography fontSize={12} color='#667085'>
                      Driver
                    </Typography>
                    <Typography fontSize={12} fontWeight={600}>
                      {driver.name}
                    </Typography>
                  </Box>
                </Box>
                <Box textAlign='right'>
                  <Typography fontSize={12} color='#667085'>
                    Phone
                  </Typography>
                  <Typography fontSize={12} fontWeight={600}>
                    {driver.phone}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      {saved && (
        <Card
          variant='elevation'
          elevation={2}
          sx={{
            backgroundColor: '#E8FFF2',
            color: 'white',
            width: '100%',
            animation: `${comeIn} 2s ease-in-out`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            position: 'absolute',
            top: 0,
            borderRadius: 0,
            p: 1,
          }}
        >
          <Check sx={{ color: 'green' }} />
          <Typography fontSize={14} color='green'>
            {edit ? 'Saved successfully' : 'Removed successfully'}
          </Typography>
        </Card>
      )}
    </>
  );
};
