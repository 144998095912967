import { useQuery } from "@tanstack/react-query";
import { getBookings } from "services/booking";

const useBookingTrips = () => {
  const query = useQuery({
    queryKey: ["GET_BOOKINGS"],
    queryFn: getBookings,
  });

  return query;
};
export default useBookingTrips;
