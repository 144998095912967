import React from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from "@mui/lab";
import { Typography, Box, styled } from "@mui/material";

const TimelineOppositeContentStyled = styled(TimelineOppositeContent)(
  ({ theme }) => ({
    flex: 0.2,
  })
);

const LocationTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: "bold",
  fontSize: "12px",
}));

const TimeTypography = styled(Typography)(({ theme }) => ({
  color: "#4caf50",
  fontWeight: "bold",
}));

const DescriptionTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const SquareDot = styled(Box)(({ theme }) => ({
  width: "12px",
  height: "12px",
  backgroundColor: "#D9D9D9",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  //margin: "0 auto",
  //   padding: "2px",
}));
interface CustomTimelineProps {
  from?: string;
  to?: string;
  start_time?: string;
  stop_time?: string;
  start_park?: string;
  stop_park?: string;
}
export default function CustomTimeline({
  from,
  to,
  start_time,
  stop_time,
  start_park,
  stop_park,
}: CustomTimelineProps) {
  return (
    <Timeline>
      <TimelineItem>
        <TimelineOppositeContentStyled>
          <LocationTypography>{from}</LocationTypography>
        </TimelineOppositeContentStyled>
        <TimelineSeparator sx={{ mt: 2 }}>
          <SquareDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <TimeTypography sx={{ textTransform: "uppercase" }}>
            {start_time}
          </TimeTypography>
          <DescriptionTypography sx={{ fontSize: "12px" }}>
            {start_park}
          </DescriptionTypography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContentStyled>
          <LocationTypography>{to}</LocationTypography>
        </TimelineOppositeContentStyled>
        <TimelineSeparator>
          <SquareDot />
        </TimelineSeparator>
        <TimelineContent>
          <TimeTypography sx={{ textTransform: "uppercase" }}>
            {stop_time}
          </TimeTypography>
          <DescriptionTypography sx={{ fontSize: "12px" }}>
            {stop_park}
          </DescriptionTypography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
