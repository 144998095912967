import http from 'utils/http';

export const login = async (body: any) => {
  const res = http.post('/pm/auth/login', body);
  return (await res).data;
};

export const verifyOtp = async (body: any) => {
  const res = http.post('/pm/auth/verify', body);
  return (await res).data;
};

export const verifyKyc = async (body: any) => {
  const res = http.post('/pm/auth/verifykyc', body);
  return (await res).data;
};

export const completeRegistration = async (body: any) => {
  const res = http.post('/pm/auth/completeregister', body);
  return (await res).data;
};

export const getParkDetails = async () => {
  const res = await http.get('/pm/booking/get-parks');
  return res?.data;
};

export const getUserProfile = async () => {
  const res = http.get('/customer/profile/get-profile');
  return (await res).data;
};
