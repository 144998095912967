import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

// Create the Axios instance
const http: AxiosInstance = axios.create({
  baseURL: 'https://www.staging.move9ja.com/api/v1/',
});

// Add a response interceptor
http.interceptors.response.use(
  (res: AxiosResponse): AxiosResponse => {
    // You can handle redirection to login or token refresh logic here

    return res; // Always return the response, otherwise the promise will hang
  },
  (error) => {
    if (error?.response?.data?.message === 'Invalid token Or Expired Token') {
      localStorage.clear();
      window.location.href = '/login';
    }
    // Handle any global errors here, like showing notifications
    return Promise.reject(error); // Ensure you reject to propagate the error
  }
);

// Add a request interceptor
http.interceptors.request.use(
  (req) => {
    const token = localStorage.getItem('token');
    if (token) {
      req.headers.Authorization = `Bearer ${token}`; // Include 'Bearer' if applicable
    }
    return req;
  },
  (error) => {
    return Promise.reject(error); // Reject the request error
  }
);

export default http;
