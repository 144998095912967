import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard = ({ children }: AuthGuardProps) => {
  const token = localStorage.getItem('token');

  // If no token exists, navigate to login
  if (!token) {
    return <Navigate to='/login' replace />;
  }

  // Render the protected content if token is present
  return <>{children}</>;
};

export default AuthGuard;
