import { HelpOutlineRounded, SendOutlined } from '@mui/icons-material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Avatar, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useAppContext } from 'context/chatProvider';
import useGetUser from 'hooks/useGetUser';
import useSocket from 'hooks/useSocket';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getDateAndTime } from 'utils/misc';

const Messages = () => {
  const navigate = useNavigate();
  const params = useParams();
  const roomId = params?.id;

  const { socket } = useSocket({});
  const { data } = useGetUser();
  const [messages, setMessages] = useState<any[]>([]);
  const [messageInput, setMessageInput] = useState('');

  const { state, dispatch } = useAppContext();

  console.log('State', JSON.parse(localStorage.getItem('appState')!)?.messages);

  useEffect(() => {
    socket?.emit('joinRoom', { roomId: roomId });

    setTimeout(() => {}, 2000);
    socket?.on('roomMessages', (e) => {
      dispatch({ type: 'SET_MESSAGES', payload: e.messages });
    });
  }, [roomId, socket]);

  const handleSendMessage = () => {
    if (messageInput.trim()) {
      socket?.emit('sendMessage', { roomId, message: messageInput });
      setMessageInput('');
    }
  };

  return (
    <Box height='100%' display='flex' flexDirection='column'>
      <Box px={1} py={2}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={700} fontSize={20}>
              Message
            </Typography>
          </Box>
          <HelpOutlineRounded
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate('/details')}
          />
        </Box>
        <Box p={2}>
          <Box display='flex' alignItems='center' gap={1} mb='6px'>
            <Avatar />
            <Typography fontSize={16} fontWeight={600}>
              John Doe
            </Typography>
            <Typography fontSize={12}>Online</Typography>
          </Box>
          <Typography
            fontSize={12}
            bgcolor='#F7FFEC'
            p='10px'
            borderRadius='4px'
            mb='24px'
          >
            You can only receive messages from a customer during a live trip.
          </Typography>
          <Box flex={1} overflow='auto' px={2} pb={8}>
            {JSON.parse(localStorage.getItem('appState')!)?.messages.map(
              (item: any, index: any) => {
                const checkIfMe = item?.sender[0] === data?._id;

                return checkIfMe ? (
                  <Box
                    key={index}
                    mb='12px'
                    display='flex'
                    alignItems='end'
                    justifyContent='flex-end'
                  >
                    <Box maxWidth='80%'>
                      <Typography fontSize={12}>
                        {getDateAndTime(item.timestamp) || 'Unknown Time'}
                      </Typography>
                      <Typography
                        fontSize={14}
                        color='white'
                        bgcolor='#379E66'
                        p='10px 14px'
                        borderRadius='8px 0px 8px 8px'
                      >
                        {item.message}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box key={index} mb='12px'>
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                      width='80%'
                    >
                      <Typography fontSize={14} fontWeight={600}>
                        {item.senderName || 'Unknown Sender'}
                      </Typography>
                      <Typography fontSize={12}>
                        {getDateAndTime(item?.timestamp)}
                      </Typography>
                    </Box>
                    <Typography
                      fontSize={14}
                      width='80%'
                      bgcolor='#F9FAFB'
                      border='1px solid #E4E7EC'
                      p='10px 14px'
                      borderRadius='0px 8px 8px 8px'
                    >
                      {item.message}
                    </Typography>
                  </Box>
                );
              }
            )}
          </Box>
          <Box
            px={2}
            py={2}
            position='sticky'
            bottom={0}
            display='flex'
            alignItems='center'
            bgcolor='white'
          >
            <TextField
              variant='standard'
              fullWidth
              InputProps={{ disableUnderline: true }}
              placeholder='Reply'
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              sx={{
                p: '10px 0px 10px 14px',
                borderRadius: '8px',
                bgcolor: '#F9F9F9',
                mr: '10px',
              }}
            />
            <Box
              height='44px'
              width='44px'
              bgcolor='#379E66'
              borderRadius='100%'
              display='flex'
              justifyContent='center'
              alignItems='center'
              onClick={handleSendMessage}
            >
              <SendOutlined
                sx={{ color: 'white', height: '24px', width: '24px' }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Messages;
