import { ArrowBack, NavigateNext } from "@mui/icons-material";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import faceBookIcon from "../assets/facebook-icon.svg";
import instagramIcon from "../assets/instagram-icon.svg";
import xIcon from "../assets/x-icon.svg";
import tikTokIcon from "../assets/tiktok-icon.svg";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const CONTACTLIST = [
    { title: "Chat us Via WhatsApp" },
    { title: "Contact us via email" },
    { title: "Frequently asked questions" },
    { title: "Suggest a feature" },
    { title: "Report a Bug" },
  ];
  const navigate = useNavigate();
  return (
    <Box p={2}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: 2,
        }}
        mb={3}
      >
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography fontWeight={700} fontSize={20}>
          Contact Us
        </Typography>
      </Box>
      {CONTACTLIST.map((item) => (
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              py: 2,
            }}
          >
            <Typography fontSize={13} fontWeight={500}>
              {item.title}
            </Typography>
            <NavigateNext fontSize="medium" sx={{ height: 20, width: 20 }} />
          </Box>
          <Divider />
        </Box>
      ))}
      <Box display="flex" flexDirection="column" py={2} gap={2}>
        <Typography fontSize={13} fontWeight={700}>
          Follow us on social media
        </Typography>
        <Box
          display="flex"
          alignContent="center"
          justifyContent="normal"
          gap={1}
        >
          <img src={faceBookIcon} alt="" height={47.66} width={47.66} />
          <img src={instagramIcon} alt="" height={47.66} width={47.66} />
          <img src={xIcon} alt="" height={47.66} width={47.66} />
          <img src={tikTokIcon} alt="" height={47.66} width={47.66} />
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: 2,
          }}
        >
          <Typography fontSize={13} fontWeight={700}>
            About Us
          </Typography>
          <NavigateNext fontSize="medium" sx={{ height: 20, width: 20 }} />
        </Box>
        <Divider />
      </Box>
    </Box>
  );
};

export default ContactUs;
