import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import inviteIcon from 'assets/invite.png';
import driversIcon from 'assets/drivers.png';
import vehiclesIcon from 'assets/vehicles.png';
import Move9jaCard from 'shared/Move9jaCard';
import OrderCard from 'shared/OrderCard';
import BottomDrawer from 'shared/BottomDrawer';
import CustomTimeline from 'shared/TravelLocationTimeline';
import { useEffect, useState } from 'react';
import DashboardLayout from 'layouts/DashboardLayout';

import Calendar from 'components/Move9jaCalendar';

import { ReactComponent as SearchIcon } from 'assets/search-icon.svg';
import { ReactComponent as LocationIcon } from 'assets/location-dashboard.svg';
import Travellers from 'shared/BottomDrawerContent/Travellers';
import TravelerDetails from 'shared/TravelerDetails';
import SingleTraveller from 'shared/BottomDrawerContent/SingleTraveller';
import { useNavigate } from 'react-router-dom';
import useThemeColor from 'hooks/useThemeColor';
import Move9jaTravelTimeline from 'shared/Move9jaTravelTimeline';
import ReasonsForDecline from 'shared/BottomDrawerContent/ReasonsForDecline';
import SetTravellersDetail from 'shared/BottomDrawerContent/SetTravellersDetail';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TripOrderContent, { ReUseAbleTripCards } from 'shared/TripOrderContent';
import React from 'react';
import TripOrderContent2 from 'shared/TripOrderContent2';
import useBookingTrips from 'hooks/useBookings';

import {
  Add,
  ArrowOutward,
  Pending,
  WalletOutlined,
} from '@mui/icons-material';
import CreateVehicle from './vehicles/CreateVehicle';
import useTrips from 'hooks/useTrips';
import TripCard from 'shared/TripCard';
import { useQuery } from '@tanstack/react-query';
import { getParkDetails } from 'services/auth';
import useSocket from 'hooks/useSocket';

const Schedule = () => {
  const [open, setOpen] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedTrip, setSelectedTrip] = React.useState(null);

  const theme = useTheme();
  useThemeColor('#000');

  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const { data, isLoading } = useBookingTrips();

  const bookings = data?.data.bookings || [];

  const { data: dataTrips, isLoading: isLoadingTrips } = useTrips();
  const { data: parkDt, isLoading: isParkLoading } = useQuery({
    queryKey: ['PARK_DETAILS'],
    queryFn: getParkDetails,
  });

  const park_details = parkDt?.data?.detials?.park;

  const token = localStorage.getItem('token');
  const { socket } = useSocket();

  useEffect(() => {
    if (token) {
      socket?.on('roomMessages', (e) => {
        console.log('roomMessagesreesponse', e);
      });
    } else {
      socket?.off('roomMessages', () => {});
    }

    return () => {
      socket?.off('roomMessages');
    };
  }, [socket, token]);

  if (isLoading) {
    return (
      <Box
        display='grid'
        height='100vh'
        width='100%'
        sx={{ placeContent: 'center' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const completedTrips = dataTrips?.data?.completed || [];

  const QUICK_LINS = [
    {
      img: vehiclesIcon,
      title: 'Vehicles',
      onClick: () => {
        setDrawerOpen(true);
      },
    },
    {
      img: driversIcon,
      title: 'Drivers',
      onClick: () => {
        navigate('/create-driver');
      },
    },
    {
      img: inviteIcon,
      title: 'Invite',
      onClick: () => {
        navigate('/profile-details?open=true');
      },
    },
  ];
  const walletCard = [
    {
      icon: <Add sx={{ color: '#379E66' }} />,
      title: 'Top Up Wallet',
      onClick: () => {
        // setOpenTopUpWallet(true);
      },
    },
    {
      icon: <ArrowOutward sx={{ color: '#379E66' }} />,
      title: 'Withdraw',
    },
    {
      icon: <WalletOutlined sx={{ color: '#379E66' }} />,
      title: 'Virtual Card',
      onClick: () => {
        setOpen(true);
      },
    },
  ];

  return (
    <>
      <DashboardLayout>
        <BottomDrawer
          open={drawerOpen}
          onClose={() => {
            setDrawerOpen(false);
          }}
        >
          <CreateVehicle />
        </BottomDrawer>
        <BottomDrawer
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <TripOrderContent2
            tripData={selectedTrip}
            bookingTrip={selectedTrip}
            onClose={() => {
              setOpen(false);
            }}
          />
        </BottomDrawer>
        <Box p={{ md: 2 }} mx={{ md: '14%' }}>
          <Box
            display={{ xs: 'block', md: 'grid' }}
            sx={{
              gridTemplateColumns: '59% 41%',
              gap: '12px',
            }}
          >
            <div>
              <Box
                p={2}
                bgcolor='#000000'
                py={4}
                color='#fff'
                borderRadius={{ md: '12px' }}
              >
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Box display='flex' alignItems='flex-start'>
                    <LocationIcon />
                    <Typography fontSize='12px' fontWeight={600} ml={1}>
                      {park_details?.address} <br />
                      <span style={{ fontWeight: 400 }}>
                        {park_details?.bus_stop}, {park_details?.state}
                      </span>
                    </Typography>
                  </Box>
                  <Box>
                    <Chip
                      sx={{ bgcolor: '#E8FFF2' }}
                      label={
                        <Box>
                          <Typography
                            color='#379E66'
                            display='flex'
                            alignItems='center'
                            gap={0.5}
                          >
                            <Box
                              height='6px'
                              width='6px'
                              borderRadius='50%'
                              bgcolor='#379E66'
                            />
                            <span style={{ fontSize: '12px', fontWeight: 600 }}>
                              {' '}
                              Available
                            </span>
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                </Box>

                <Calendar />

                <TextField
                  placeholder='Search'
                  variant='standard'
                  onClick={() => navigate('/search')}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  inputProps={{
                    sx: {
                      '&::placeholder': {
                        padding: 1,
                        marginTop: 1.5,
                      },
                    },
                  }}
                  sx={{
                    bgcolor: '#fff',
                    marginTop: 2,
                    padding: 1,
                    paddingX: 2,
                    borderRadius: '8px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    cursor: 'pointer',
                  }}
                />
              </Box>

              <Box mx={1}>
                <Box display={{ md: 'none', xs: 'block' }}>
                  <Move9jaCard title='Quick links'>
                    <Box
                      display='grid'
                      gap={1}
                      gridTemplateColumns='1fr 1fr 1fr'
                      px={2}
                    >
                      {QUICK_LINS.map(({ title, img, onClick }) => {
                        return (
                          <Box
                            sx={{ cursor: 'pointer' }}
                            width='100%'
                            display='flex'
                            alignItems='center'
                            flexDirection='column'
                            justifyContent='center'
                            border='1px solid #E4E7EC'
                            py={2}
                            borderRadius='8px'
                            my={1}
                            onClick={onClick}
                          >
                            {typeof img === 'object' ? (
                              img
                            ) : (
                              <img src={img} alt='hello-img' width='32px' />
                            )}
                            <Typography
                              textAlign='center'
                              fontSize='10px'
                              mt={2}
                              color='#344054'
                            >
                              {title}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Move9jaCard>
                </Box>

                <Move9jaCard title='Bookings'>
                  {isLoading && (
                    <Box display='grid' sx={{ placeItems: 'center' }}>
                      <CircularProgress sx={{ color: '#000' }} />
                    </Box>
                  )}
                  {bookings.length === 0 && !isLoading && (
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      p={2}
                    >
                      <Typography>No new bookings</Typography>
                    </Box>
                  )}
                  {bookings.map((trip: any) => (
                    <OrderCard
                      onClick={() => {
                        setSelectedTrip(trip);
                        setOpen(true);
                      }}
                      from={trip.from}
                      to={trip.to}
                      startpark={trip.parkbus_stop}
                      starttime={trip.starttime}
                      stoppark={trip.stop_park}
                      endtime={trip.endtime}
                      chioce={trip.choice}
                      noOfSeats={trip.seats}
                      seatsLeft={trip.available_seats}
                      status={trip.trip_status}
                    />
                  ))}
                </Move9jaCard>
              </Box>
            </div>
            <Box>
              {/* <Move9jaTravelTimeline /> */}
              {/* <Card
                sx={{
                  height: '146px',
                  borderRadius: 2,
                  display: { md: 'block', xs: 'none' },
                }}
                elevation={0}
              >
                <Box bgcolor='#025F2D' p={2}>
                  <Typography color='white' fontSize={12} mb={1}>
                    Pocket Balancess
                  </Typography>
                  <Typography color='white' fontSize={12}>
                    ₦ 1,005,490.00
                  </Typography>
                </Box>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  bgcolor='#379E66'
                  p={2}
                >
                  <Typography color='white' fontSize={25} fontWeight={700}>
                    ₦ 90,090.00
                  </Typography>
                  <Pending
                    sx={{ color: '#fff', height: '36px', width: '36px' }}
                    onClick={() => {
                      navigate('/cards');
                    }}
                  />
                </Box>
              </Card> */}

              <Box sx={{ mt: 0 }} display={{ md: 'block', xs: 'none' }}>
                <Move9jaCard title='Quick links'>
                  <Box
                    display='grid'
                    gap={1}
                    gridTemplateColumns='1fr 1fr 1fr'
                    px={2}
                  >
                    {QUICK_LINS.map(({ title, img, onClick }) => {
                      return (
                        <Box
                          sx={{ cursor: 'pointer' }}
                          width='100%'
                          display='flex'
                          alignItems='center'
                          flexDirection='column'
                          justifyContent='center'
                          border='1px solid #E4E7EC'
                          py={2}
                          borderRadius='8px'
                          my={1}
                          onClick={onClick}
                        >
                          {typeof img === 'object' ? (
                            img
                          ) : (
                            <img src={img} alt='hello-img' width='32px' />
                          )}
                          <Typography
                            textAlign='center'
                            fontSize='10px'
                            mt={2}
                            color='#344054'
                          >
                            {title}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Move9jaCard>
              </Box>
              <Box display={{ md: 'block', xs: 'none' }}>
                <ReUseAbleTripCards title='History' subtitle='View all'>
                  {isLoadingTrips && (
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      p={2}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                  {completedTrips.map((trip: any) => (
                    <TripCard
                      onClick={() => {
                        setSelectedTrip({ ...trip, status: 'Completed' });
                        setOpen(true);
                      }}
                      from={trip.from}
                      to={trip.to}
                      start={trip.parkbus_stop}
                      // start_time={trip.starttime}
                      stop={trip.stop_park}
                      status='Completed'
                      //stop_time={trip.endtime}
                    />
                  ))}
                  {completedTrips?.length === 0 && !isLoadingTrips && (
                    <Typography
                      display='grid'
                      sx={{ placeContent: 'center' }}
                      m={5}
                      fontSize={12}
                    >
                      No booking available here yet
                    </Typography>
                  )}
                </ReUseAbleTripCards>
              </Box>
            </Box>
          </Box>
        </Box>
      </DashboardLayout>

      {/* <BottomDrawer open={false} onClose={() => {}}>
        <Travellers />
      </BottomDrawer>

      <BottomDrawer open={false} onClose={() => {}}>
        <SingleTraveller />
      </BottomDrawer>

      <BottomDrawer open={openDecline} onClose={() => setOpenDecline(false)}>
        <ReasonsForDecline />
      </BottomDrawer>
      <BottomDrawer open={openDetails} onClose={() => setOpenDetails(false)}>
        <SetTravellersDetail />
      </BottomDrawer> */}
    </>
  );
};

export default Schedule;
