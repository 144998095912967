import { Check, CheckCircleOutline, Wallet } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Move9jaCard from './Move9jaCard';
import TravelerDetails from './TravelerDetails';
import { Divider, Rating } from '@mui/material';
import Car from '../assets/car.png';
import { ReUseAbleTripCards } from './TripOrderContent';
import TripCard from './TripCard';
import NavigateNext from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';

const TripConcluded = ({ tripData }: any) => {
  const navigate = useNavigate();

  return (
    <Box px={2}>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        mt={3}
        mb={2}
      >
        <CheckCircleOutline
          sx={{
            color: '#079455',
            height: 53.33,
            width: 53.33,
          }}
        />

        <Typography mt={2} fontSize='20px' fontWeight={700}>
          Your trip has been concluded!!
        </Typography>
      </Box>
      <TripCard
        to={tripData.to}
        from={tripData.from}
        status={tripData.trip_status}
        start={tripData.parkbus_stop}
        stop={tripData.stop_park}
      />
      {/* <ReUseAbleTripCards title="Bus Details">
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={1} p={1}>
          <img src={Car} alt="" height={74} width={74} />
          <Box fontSize={12}>
            <Typography fontSize={10} color="#98A2B3">
              Vehicle
            </Typography>
            <Typography fontSize={12} fontWeight={600}>
              {tripData.car_model}
            </Typography>
            <Typography fontSize={10} color="#98A2B3">
              Driver
            </Typography>
            <Typography fontSize={12} fontWeight={600}>
              Mr Adetola
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-end" flexDirection="column">
            <Typography fontSize={10} color="#98A2B3">
              License Plate
            </Typography>
            <Typography fontSize={12} fontWeight={600}>
              {tripData.license_plate}
            </Typography>
            <Typography fontSize={10} color="#98A2B3">
              Phone
            </Typography>
            <Typography fontSize={12} fontWeight={600}>
              08107893792
            </Typography>
          </Box>
        </Box>
      </ReUseAbleTripCards> */}
      <Move9jaCard title="Traveller's Details">
        <Box m={1}>
          {tripData.passengers.map((pass: any) => (
            <TravelerDetails
              amount={pass.pass.amount}
              name={pass.pass.fullname}
              payMtd={pass.pass.paymentType}
              phone={pass.pass.phoneNumber}
              seatNumber={pass.pass.selected_seat}
            />
          ))}
          <Box
            mt={3}
            px={2}
            pb={1}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Typography
              mx='auto'
              fontSize='12px'
              fontWeight={700}
              color='#0085FF'
              component='button'
            >
              View more
            </Typography>
          </Box>
        </Box>
      </Move9jaCard>
      {/* <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            py: 2,
          }}
          onClick={() => {
            navigate('/earnings-breakdown');
          }}
        >
          <Typography
            fontSize={13}
            fontWeight={500}
            display='flex'
            alignItems='center'
            gap={1}
          >
            <Wallet /> <span> See earnings breakdown</span>
          </Typography>
          <NavigateNext fontSize='medium' sx={{ height: 20, width: 20 }} />
        </Box>
        <Divider />
      </Box> */}
      {/* <Box sx={{ display: 'grid', placeItems: 'center', my: 1, mt: 4 }}>
        <Typography fontSize='20px' fontWeight={600} mb={2}>
          Rating 3.5
        </Typography>
        <Rating value={3} size='large' sx={{ fontSize: '32px' }} />
      </Box> */}
    </Box>
  );
};
export default TripConcluded;
