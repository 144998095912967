import http from 'utils/http';

export const getWalletHistory = async () => {
  const res = http.get('/pm/booking/get-wallet-history');
  return (await res).data;
};

export const getWalletBalance = async () => {
  const res = http.get('/pm/booking/get-wallet-balance');
  return (await res).data;
};
