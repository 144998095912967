import { useState, useEffect } from "react";
import { addDays, format } from "date-fns";

const useCalendar = (initialDate = new Date()) => {
  const [currentDate, setCurrentDate] = useState(initialDate);
  const [currentMonth, setCurrentMonth] = useState(format(initialDate, "MMM"));
  const [days, setDays] = useState<any>([]);

  useEffect(() => {
    generateCalendar(currentDate);
  }, [currentDate]);

  const generateCalendar = (date: any) => {
    let start = date;
    const daysArray = [];
    for (let i = 0; i < 6; i++) {
      daysArray.push(start);
      start = addDays(start, 1);
    }
    setDays(daysArray);
    setCurrentMonth(format(date, "MMM"));
  };

  const goToPreviousDays = () => {
    setCurrentDate((prevDate) => addDays(prevDate, -6));
  };

  const goToNextDays = () => {
    setCurrentDate((prevDate) => addDays(prevDate, 6));
  };

  return {
    currentMonth,
    days,
    goToPreviousDays,
    goToNextDays,
  };
};

export default useCalendar;
