import http from 'utils/http';

export const getNotifications = async () => {
  const res = await http.get('/customer/profile/get-all-notification');
  return res.data;
};

export const getAllRooms = async () => {
  const res = await http.get('/customer/profile/get-all-rooms');
  return res.data;
};
