import http from "utils/http";

export const getIndividualTripUserDetails = async () => {
  const res = http.get("/pm/booking/gettravellerdetails/trip_id/passenger_id");
  return (await res).data;
};

export const getTripHistory = async () => {
  const res = http.get("/pm/booking/get-trips");
  return (await res).data;
};
