import {
  ArrowBack,
  CardGiftcard,
  LocalFireDepartment,
  NavigateNext,
} from '@mui/icons-material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import useThemeColor from 'hooks/useThemeColor';

import { ProfileLayout } from 'layouts/ProfileLayout';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getParkDetails } from 'services/auth';
import BottomDrawer from 'shared/BottomDrawer';

import ReferralLinkContent from 'shared/BottomDrawerContent/ReferalLinkContent';

export const Profile = () => {
  const PROFILELIST = [
    {
      title: 'Update profile',
      onClick: () => {
        navigate('/profile-details');
      },
    },
    {
      title: 'Bank payouts',
      onClick: () => {
        navigate('/payouts');
      },
    },
    {
      title: 'Preferences',
      onClick: () => {
        navigate('/preferences');
      },
    },
    {
      title: 'Privacy Policy',
      onClick: () => {
        navigate('/privacy-policy');
      },
    },
  ];
  const navigate = useNavigate();
  useThemeColor('#000');
  const [open, setOpen] = React.useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ['PARK_DETAILS'],
    queryFn: getParkDetails,
  });

  const parkDetails = data?.data?.detials;

  if (isLoading) {
    return (
      <Box
        height='100vh'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ProfileLayout>
      <BottomDrawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <ReferralLinkContent />
      </BottomDrawer>
      <Box>
        <Box
          sx={{
            bgcolor: '#000000',
            height: '284px',
          }}
          mb={1}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              gap: 2,
            }}
            p={2}
          >
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack sx={{ color: '#FFFFFF' }} />
            </IconButton>
            <Typography fontWeight={700} fontSize={20} color='white'>
              Profile
            </Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            mx={3}
          >
            <Stack
              bgcolor='#1570EF'
              color='#FFF'
              height={59}
              width={59}
              justifyContent='center'
              alignItems='center'
              borderRadius='100%'
              position='relative'
            >
              <Typography fontSize={23.6}>
                {' '}
                {parkDetails?.account_name[0]}
              </Typography>
              <Stack
                position='absolute'
                bottom={0}
                right={0}
                borderRadius='100%'
                bgcolor='white'
                display='flex'
                justifyContent='center'
                alignItems='center'
                height={22}
                width={22}
              >
                <IconButton
                  onClick={() => {
                    navigate('/profile-details');
                  }}
                >
                  <DriveFileRenameOutlineIcon
                    sx={{ color: 'green', height: 12, width: 12 }}
                  />
                </IconButton>
              </Stack>
            </Stack>
            <Typography color='#FFF' fontSize={18} my={1}>
              {parkDetails?.account_name}
            </Typography>
            <Typography
              color='#FFF'
              fontSize={10}
              display='flex'
              gap={2}
              alignItems='center'
            >
              <p>{data?.data?.detials?.user_details?.['email']}</p>
              <Chip
                size='small'
                label='Verified'
                sx={{ bgcolor: '#fff', color: '#667085' }}
                icon={<LocalFireDepartment sx={{ color: 'green' }} />}
              />
            </Typography>
          </Box>
          <Divider variant='fullWidth' color='gray' sx={{ my: 2 }} />
          <Box
            px={3}
            borderColor='#FFF'
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box textAlign='center'>
              <Typography fontSize={10} color='#667085'>
                Referal Count
              </Typography>
              <Typography fontSize={12} color='#FFF'>
                {parkDetails?.user_details?.referral_count}
              </Typography>
            </Box>
            <Box textAlign='center'>
              <Typography fontSize={10} color='#667085'>
                Referral Bonus Balance
              </Typography>
              <Typography fontSize={12} color='#FFF'>
                {parkDetails?.user_details?.referral_bonus_balance}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box p={2}>
          <Button
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 1.5,
              mb: 1,
              borderRadius: 3,
              bgcolor: '#9747FF',
            }}
            fullWidth
            variant='contained'
            onClick={() => setOpen(true)}
          >
            <Typography
              fontSize={13}
              fontWeight={500}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <CardGiftcard />
              NGN 500 gift for you
            </Typography>
            <NavigateNext fontSize='medium' sx={{ height: 20, width: 20 }} />
          </Button>
          {PROFILELIST.map((item) => (
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  py: 2,
                  cursor: 'pointer',
                }}
                onClick={item.onClick}
              >
                <Typography fontSize={13} fontWeight={500}>
                  {item.title}
                </Typography>
                <NavigateNext
                  fontSize='medium'
                  sx={{ height: 20, width: 20 }}
                />
              </Box>
              <Divider />
            </Box>
          ))}

          <Button
            sx={{ mt: 2 }}
            onClick={() => {
              localStorage.clear();
              window.location.href = '/';
            }}
            fullWidth
            variant='contained'
          >
            Logout
          </Button>
        </Box>
      </Box>
    </ProfileLayout>
  );
};
