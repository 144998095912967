import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  Select,
  FormControl,
  IconButton,
  InputAdornment,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useNavigate } from 'react-router-dom';
import { createVehicle } from 'services/vehicle';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { handleMutationError } from 'utils/handler';
import SelectableDays from 'shared/SelectableDays';
import { convertObject } from 'utils/vehicleSubmitConvert';

const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as const;

// Validation Schema
const schema = yup.object({
  repeat: yup.string().required('Repeat is required'),
  estimatedTravelTime: yup
    .string()
    .required('Estimated travel time is required'),
  seats: yup
    .number()
    .required('Number of seats is required')
    .min(1, 'At least 1 seat is required'),
  choice: yup.string().required('Choice is required'),
  price: yup
    .number()
    .required('Price is required')
    .positive('Price must be positive'),
  license_plate: yup.string().required('License plate is required'),
  front_photo: yup.mixed().required('Front photo is required'),
  back_photo: yup.mixed().required('Back photo is required'),
  stop_park: yup.string().required('You must select a stop park'),
  selectedDays: yup
    .array()
    .of(yup.string().oneOf(daysOfWeek, 'Invalid day of the week'))
    .min(1, 'At least one day must be selected')
    .max(7, 'Cannot select more than 7 days')
    .when('repeat', (repeat, schema) =>
      //@ts-ignore
      repeat === 'Recurring'
        ? schema.required('Selected days are required')
        : schema.notRequired()
    ),

  // selectedDays: yup
  //   .array()
  //   .of(
  //     yup
  //       .mixed()
  //       .oneOf(daysOfWeek, 'Invalid day of the week')
  //       .required('Day is required')
  //   )
  //   .min(1, 'At least one day must be selected')
  //   .max(7, 'Cannot select more than 7 days')
  //   .when('repeat', {
  //     //@ts-ignore
  //     is: 'Recurring',
  //     then: yup.array().required('Selected days are required'),
  //     otherwise: yup.array().notRequired(),
  //   }),
});

export const AddNewVehicle = ({ data }: any) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    // reset,
    setError,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const dataLocation = data;
  console.log('dataLocation', dataLocation);

  const mutation = useMutation({
    mutationFn: createVehicle,
    onSuccess: ({ data }) => {
      toast.success('Vehicle saved successfully');
      window.location.reload(); //so that the page reloads and the drawer can close
    },
    onError: (err) => {
      handleMutationError(err);
    },
  });

  const onSubmit = (data: any) => {
    const convertedData = convertObject({ ...data, ...dataLocation });

    mutation.mutate(convertedData);
  };

  const repeatValue = watch('repeat');

  return (
    <>
      <Box p={2}>
        {/* Header */}
        <Box display='flex' pt={4} alignItems='center' mb={4}>
          <IconButton
            onClick={() => {
              navigate('/dashboard');
            }}
          >
            <ArrowBackIcon fontSize='small' />
          </IconButton>
          <Typography variant='h6' fontWeight='bold' ml={1}>
            Add New Vehicle
          </Typography>
        </Box>

        {/* Form */}
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Repeat Field */}
          <FormControl fullWidth sx={{ my: 2 }}>
            <Typography fontSize={12} fontWeight={600} mb={0}>
              Schedule Type
            </Typography>
            <Controller
              name='repeat'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <Select
                  {...field}
                  displayEmpty
                  variant='standard'
                  inputProps={{ disableUnderline: true }}
                  disableUnderline
                  sx={{
                    bgcolor: '#F9F9F9',
                    py: 1,
                    px: 2,
                    borderRadius: 3,
                    outline: 'none',
                    border: 'none',
                  }}
                  error={!!errors.repeat}
                >
                  <MenuItem value='Specific'>Specific</MenuItem>
                  <MenuItem value='Recurring'>Recurring</MenuItem>
                </Select>
              )}
            />
            {errors.repeat && (
              <Typography color='error'>{errors.repeat.message}</Typography>
            )}
          </FormControl>

          {/* Estimated Travel Time Field */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Typography fontSize={12} fontWeight={600} mb={0}>
              Estimated Travel Time
            </Typography>
            <Controller
              name='estimatedTravelTime'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <Select
                  {...field}
                  displayEmpty
                  variant='standard'
                  inputProps={{ disableUnderline: true }}
                  disableUnderline
                  sx={{
                    bgcolor: '#F9F9F9',
                    py: 1,
                    px: 2,
                    borderRadius: 3,
                    outline: 'none',
                    border: 'none',
                  }}
                  label='Estimated Travel Time'
                  error={!!errors.estimatedTravelTime}
                >
                  <MenuItem value='2'>2hrs</MenuItem>
                  <MenuItem value='4'>4hrs</MenuItem>
                  <MenuItem value='6'>6hrs</MenuItem>
                </Select>
              )}
            />
            {errors.estimatedTravelTime && (
              <Typography color='error'>
                {errors.estimatedTravelTime.message}
              </Typography>
            )}
          </FormControl>

          {/* Seats Field */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Typography fontSize={12} fontWeight={600} mb={0}>
              Seats
            </Typography>
            <Controller
              name='seats'
              control={control}
              //@ts-ignore
              defaultValue=''
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='standard'
                  InputProps={{ disableUnderline: true }}
                  sx={{ borderRadius: '8px', bgcolor: '#F9F9F9', p: 1 }}
                  placeholder='Number of seats available'
                  error={!!errors.seats}
                  helperText={errors.seats?.message}
                />
              )}
            />
          </FormControl>

          {/* Stop Park */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Typography fontSize={12} fontWeight={600} mb={0}>
              Bus Stop
            </Typography>
            <Controller
              name='stop_park'
              control={control}
              //@ts-ignore
              defaultValue=''
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='standard'
                  InputProps={{ disableUnderline: true }}
                  sx={{ borderRadius: '8px', bgcolor: '#F9F9F9', p: 1 }}
                  placeholder='Input the nearest bus stop.'
                  error={!!errors.seats}
                  helperText={errors.seats?.message}
                />
              )}
            />
          </FormControl>

          {/* Choice Field */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Typography fontSize={12} fontWeight={600} mb={0}>
              Choice
            </Typography>
            <Controller
              name='choice'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <Select
                  {...field}
                  displayEmpty
                  variant='standard'
                  inputProps={{ disableUnderline: true }}
                  disableUnderline
                  sx={{
                    bgcolor: '#F9F9F9',
                    py: 1,
                    px: 2,
                    borderRadius: 3,
                    outline: 'none',
                    border: 'none',
                  }}
                  error={!!errors.choice}
                >
                  <MenuItem value='Exclusive'>Exclusive</MenuItem>
                  <MenuItem value='Standard'>Standard</MenuItem>
                </Select>
              )}
            />
            {errors.choice && (
              <Typography color='error'>{errors.choice.message}</Typography>
            )}
          </FormControl>

          {/* Price Field */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Typography fontSize={12} fontWeight={600} mb={1}>
              Price
            </Typography>
            <Controller
              name='price'
              control={control}
              //@ts-ignore
              defaultValue=''
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='standard'
                  sx={{ borderRadius: '8px', bgcolor: '#F9F9F9', p: 1 }}
                  placeholder='Price'
                  type='number'
                  error={!!errors.price}
                  helperText={errors.price?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        {' '}
                        <Typography>₦</Typography>{' '}
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                />
              )}
            />
          </FormControl>

          {/* Plate number Field */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Typography fontSize={12} fontWeight={600} mb={1}>
              Plate number
            </Typography>
            <Controller
              name='license_plate'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='standard'
                  sx={{ borderRadius: '8px', bgcolor: '#F9F9F9', p: 1 }}
                  placeholder='Plate Number'
                  type='text'
                  error={!!errors.license_plate}
                  helperText={errors.license_plate?.message}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              )}
            />
          </FormControl>

          {repeatValue === 'Recurring' && (
            <SelectableDays
              setValue={setValue}
              error={errors.selectedDays?.message as string}
              // resetField={reset}
              setError={setError}
            />
          )}

          {/* Front Photo Field */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Typography fontSize={12} fontWeight={600} mb={1}>
              Front Photo
            </Typography>
            <Controller
              name='front_photo'
              control={control}
              //@ts-ignore
              defaultValue={undefined}
              render={({ field }) => (
                <TextField
                  {...field}
                  type='file'
                  variant='standard'
                  sx={{ borderRadius: '8px', bgcolor: '#F9F9F9', p: 1 }}
                  inputProps={{
                    accept: 'image/*',
                  }}
                  error={!!errors.front_photo}
                  helperText={errors.front_photo?.message}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              )}
            />
          </FormControl>

          {/* Back Photo Field */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Typography fontSize={12} fontWeight={600} mb={1}>
              Back Photo
            </Typography>
            <Controller
              name='back_photo'
              control={control}
              //@ts-ignore
              defaultValue={null}
              render={({ field }) => (
                <TextField
                  {...field}
                  type='file'
                  variant='standard'
                  sx={{ borderRadius: '8px', bgcolor: '#F9F9F9', p: 1 }}
                  inputProps={{
                    accept: 'image/*',
                  }}
                  error={!!errors.back_photo}
                  helperText={errors.back_photo?.message}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              )}
            />
          </FormControl>

          {/* Submit Button */}
          <Button
            disabled={!!mutation.isPending}
            type='submit'
            variant='contained'
            fullWidth
            sx={{ borderRadius: '100px', mt: 4, p: 1, bgcolor: 'green' }}
          >
            {mutation?.isPending ? 'Loading...' : 'Save'}
          </Button>
        </form>
      </Box>
    </>
  );
};
