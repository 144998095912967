import { Box, Button, Card, Typography, TextField } from '@mui/material';
import { ReactComponent as BikeIcon } from 'assets/package-icon.svg';
import { ReactComponent as PackageIcon } from 'assets/bicycle-icon.svg';
import Car from 'assets/car.png';
import Sienna from 'assets/sienna.png';
import SmallBus from 'assets/small-bus.png';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import BottomDrawer from 'shared/BottomDrawer';
import { AddNewVehicle } from 'pages/AddNewVehicle';

// Validation schema
const validationSchema = Yup.object().shape({
  vehicle: Yup.string().required('Vehicle selection is required'),
  dateOfTravel: Yup.string().required('Date of travel is required'),
  timeOfTravel: Yup.string().required('Time of travel is required'),
  from: Yup.string().required('Origin is required'),
  to: Yup.string().required('Destination is required'),
});

// Form data type
interface FormData {
  vehicle: string;
  dateOfTravel: string;
  timeOfTravel: string;
  from: string;
  to: string;
}

export const AddVehicle = () => {
  const [selected, setSelected] = useState<number | null>(null);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [submittedData, setSubmittedData] = useState<FormData | null>(null);

  // Form setup
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      vehicle: '',
      dateOfTravel: '',
      timeOfTravel: '',
      from: '',
      to: '',
    },
  });

  // Handle card selection
  const onClickCard = (index: number, item: Vehicle) => {
    setSelected(index);
    setValue('vehicle', item.title); // Set selected vehicle
  };

  // Form submission
  const onSubmit = (data: FormData) => {
    // navigate("/add-new-vehicle", { state: data });
    setSubmittedData(data);
    setDrawerOpen(true); // Open drawer instead of navigating
  };

  return (
    <>
      <Box p={2} component='form' onSubmit={handleSubmit(onSubmit)}>
        <Typography variant='subtitle1' fontWeight={600} mt={2}>
          Destination
        </Typography>

        <CustomTimeline control={control} errors={errors} />

        <Typography variant='subtitle1' fontWeight={600} mt={2}>
          Select Vehicle
        </Typography>
        <VehicleSelection selected={selected} onClickCard={onClickCard} />

        <Typography variant='subtitle1' mt={4} mb={1}>
          Date of Travel
        </Typography>
        <Controller
          name='dateOfTravel'
          control={control}
          render={({ field }) => (
            <TextField
              variant='standard'
              type='date'
              InputProps={{ disableUnderline: true }}
              sx={{ borderRadius: '8px', bgcolor: '#F9F9F9', p: 1 }}
              fullWidth
              {...field}
              error={!!errors.dateOfTravel}
              helperText={errors.dateOfTravel?.message}
            />
          )}
        />

        <Typography variant='subtitle1' mt={4} mb={1}>
          Time of Travel
        </Typography>
        <Controller
          name='timeOfTravel'
          control={control}
          render={({ field }) => (
            <TimePicker
              value={field.value ? new Date(field.value) : null}
              sx={{ width: '100%' }}
              onChange={(newValue) => field.onChange(newValue)}
              //@ts-ignore
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  InputProps={{ disableUnderline: true }}
                  sx={{ borderRadius: '8px', bgcolor: '#F9F9F9', p: 1 }}
                  fullWidth
                  error={!!errors.timeOfTravel}
                  helperText={errors.timeOfTravel?.message}
                />
              )}
            />
          )}
        />

        <Button
          variant='contained'
          sx={{ borderRadius: '100px', mt: 4, p: 1 }}
          fullWidth
          type='submit'
        >
          Continue
        </Button>
      </Box>
      <BottomDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <AddNewVehicle data={submittedData} />
      </BottomDrawer>
    </>
  );
};

// Vehicle selection component
interface Vehicle {
  title: string;
  img: string;
}

const vehicles: Vehicle[] = [
  { title: 'Car', img: Car },
  { title: 'Sienna', img: Sienna },
  { title: 'Bus', img: SmallBus },
];

interface VehicleSelectionProps {
  selected: number | null;
  onClickCard: (index: number, item: Vehicle) => void;
}

const VehicleSelection: React.FC<VehicleSelectionProps> = ({
  selected,
  onClickCard,
}) => (
  <Box display='grid' gridTemplateColumns='1fr 1fr 1fr' gap={1} my={1}>
    {vehicles.map((item, index) => (
      <Card
        key={index}
        elevation={selected === index ? 6 : 2}
        sx={{
          p: 1,
          bgcolor: selected === index ? '#E8FFF2' : 'white',
          cursor: 'pointer',
          position: 'relative',
          transition: 'transform 0.2s ease',
          ':hover': { transform: 'scale(1.05)' },
        }}
        onClick={() => onClickCard(index, item)}
      >
        <img
          src={item.img}
          alt={item.title}
          style={{ objectFit: 'contain', height: 74, width: 74 }}
        />
        <Typography fontSize={12} textAlign='center'>
          {item.title}
        </Typography>
        {selected === index && (
          <Box
            position='absolute'
            top={4}
            left={4}
            border='2px solid green'
            height='20px'
            width='20px'
            borderRadius='100%'
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Box
              border='2px solid green'
              bgcolor='green'
              height='12px'
              width='12px'
              borderRadius='100%'
            />
          </Box>
        )}
      </Card>
    ))}
  </Box>
);

// Custom Timeline Component
interface CustomUIProps {
  control: any;
  errors: any;
}

const CustomTimeline: React.FC<CustomUIProps> = ({ control, errors }) => (
  <Box width='100%' mt={2}>
    <Timeline
      sx={{
        width: '100%',
        p: 0,
        '.MuiTimelineOppositeContent-root': { display: 'none' },
        '.MuiTimelineItem-missingOppositeContent:before': {
          flex: 0,
          padding: 0,
        },
        '.css-1hqhx8o-MuiTimelineConnector-root': {
          width: '1px',
          border: '1px solid #98A2B3',
        },
        '.css-v6mo1y-MuiTimelineDot-root': {
          m: 0,
          width: '40px',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: null,
          bgcolor: '#fff',
          border: '1px solid #E4E7EC',
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot>
            <BikeIcon />
          </TimelineDot>
          <TimelineConnector sx={{ border: '1px dashed #98A2B3' }} />
        </TimelineSeparator>
        <TimelineContent>
          <Controller
            name='from'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                placeholder='From'
                variant='standard'
                sx={{ bgcolor: '#F9F9F9', p: 1, borderRadius: '8px', mt: -1 }}
                InputProps={{ disableUnderline: true }}
                error={!!errors.from}
                helperText={errors.from?.message}
                autoComplete='off'
              />
            )}
          />
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot>
            <PackageIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Controller
            name='to'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                placeholder='To'
                variant='standard'
                sx={{ bgcolor: '#F9F9F9', p: 1, borderRadius: '8px', mt: -2 }}
                InputProps={{ disableUnderline: true }}
                error={!!errors.to}
                helperText={errors.to?.message}
                autoComplete='off'
              />
            )}
          />
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  </Box>
);
