import {
  Box,
  Button,
  Card,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import DashboardLayout from 'layouts/DashboardLayout';

import vehicleImg from 'assets/vehicles.png';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Move9jaCard from 'shared/Move9jaCard';
import VehicleCard from 'shared/VehicleCard';

import siennaImg from 'assets/vehicle-sienna.png';
import Move9jaButtonTabToggle from 'components/Move9jaButtonTabToggle';
import BottomDrawer from 'shared/BottomDrawer';
import { AddVehicle } from 'shared/BottomDrawerContent/AddVehicle';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Check } from '@mui/icons-material';
import { keyframes } from '@mui/material';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { EditDriverContact } from 'shared/EditDriverContact';
import { EditVehicle } from 'shared/BottomDrawerContent/EditVehicle';
import AddNewDV from 'shared/BottomDrawerContent/AddNewDV';
import { Keyframes } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { getVehicles } from 'services/vehicle';

const slideIn: Keyframes = keyframes`
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
`;
const CreateVehicle = () => {
  const [openVehicleDrawer, setOpenVehicleDrawer] = useState(false);
  const [openEditVehicle, setOpenEditVehicle] = useState(false);
  const navigate = useNavigate();
  const [saved, setSaved] = useState(false);
  const [changeState, setChangeState] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  useEffect(() => {
    if (isDesktop) {
      // Redirect to the home page and open the drawer
      navigate('/dashboard', { state: { openDrawer: true } });
    }
  }, [isDesktop, navigate]);
  const handleChangeState = () => {
    setChangeState(!changeState);
  };

  const { data, isLoading } = useQuery({
    queryKey: ['GET_VEHICLES'],
    queryFn: getVehicles,
  });

  const vechicleDetails = data?.data;

  return (
    <>
      <>
        <Box p={2}>
          <Typography
            display='flex'
            alignItems='center'
            fontWeight={700}
            fontSize='20px'
          >
            <IconButton
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <span style={{ marginLeft: '10px' }}>Create Vehicle</span>
          </Typography>
          <Box
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setOpenVehicleDrawer(true);
            }}
            display='flex'
            alignItems='center'
            justifyContent='center'
            flexDirection='column'
            border='1px solid #E4E7EC'
            bgcolor='#F2F2F2'
            borderRadius='8px'
            py={3}
            mt={2}
          >
            <img width='32px' src={vehicleImg} alt='vehicle' />
            <Typography fontSize='10px' color='#344054'>
              + Create new
            </Typography>
          </Box>

          <Move9jaCard
            title='Recently Saved'
            endText={
              <Typography fontSize='12px' color='#DA4733'>
                Cancel
              </Typography>
            }
          >
            <Box p={2}>
              {/* //@ts-ignore */}
              {vechicleDetails?.result?.map((item: any) => (
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  {!changeState ? (
                    ''
                  ) : (
                    <RemoveCircleIcon
                      sx={{ color: '#DA4733', height: 30, width: 30 }}
                      fontSize='medium'
                      onClick={() => {}} // this function will remove a vehicle from the list
                    />
                  )}
                  <VehicleCard
                    {...item}
                    onClick={() => {
                      setSelectedVehicle(item);
                      setOpenEditVehicle(true);
                    }}
                  />
                </div>
              ))}
            </Box>
          </Move9jaCard>
        </Box>
      </>
      <BottomDrawer
        open={openVehicleDrawer}
        onClose={() => {
          setOpenVehicleDrawer(false);
        }}
      >
        <AddNewDV />
      </BottomDrawer>
      <BottomDrawer
        open={openEditVehicle}
        onClose={() => {
          setOpenEditVehicle(false);
        }}
      >
        <EditVehicle data={selectedVehicle} />
      </BottomDrawer>
      {saved && (
        <Card
          variant='elevation'
          sx={{
            backgroundColor: '#DA4733',
            color: 'white',
            width: '100%',
            animation: `${slideIn} 2s ease-in-out`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            position: 'absolute',
            top: 0,
            borderRadius: 0,
            p: 1,
          }}
        >
          <Check sx={{ color: 'green' }} />
          <Typography fontSize={14}> Saved successfully</Typography>
        </Card>
      )}
    </>
  );
};

export default CreateVehicle;
