import React from "react";
import { useMediaQuery, useTheme, Button, Drawer, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CreateVehicle from "./CreateVehicle";

const ResponsiveComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleButtonClick = () => {
    if (isMobile) {
      navigate("/create-vehicle"); // On mobile, navigate to new page
    } else {
      setDrawerOpen(true); // On desktop, open the drawer
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div>
      <h1>Home Page</h1>
      <Button onClick={handleButtonClick}>Open Page</Button>

      {/* Desktop Drawer */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box sx={{ width: 450, p: 2 }}>
          <CreateVehicle />
          <Button onClick={handleDrawerClose}>Close Drawer</Button>
        </Box>
      </Drawer>
    </div>
  );
};

export default ResponsiveComponent;
