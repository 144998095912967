import { Box, Button, Card, Divider, Typography } from '@mui/material';

import TextField from '@mui/material/TextField';
import { ReactComponent as BikeIcon } from 'assets/package-icon.svg';
import { ReactComponent as PackageIcon } from 'assets/bicycle-icon.svg';
import Car from 'assets/car.png';
import Sienna from 'assets/sienna.png';
import SmallBus from 'assets/small-bus.png';

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';

import { useState } from 'react';

import React from 'react';

export const EditVehicle = ({ data }: any) => {
  const [selected, setSelected] = useState(null);
  const onClickCard = (index: any) => {
    setSelected(index);
  };

  const date = new Date(data.schedule.date);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const formattedDate = date.toLocaleDateString('en-US', options);

  const someData = [
    {
      title: 'Date - Created',
      info: data?.createdAt.split('T')[0],
    },

    { title: 'Time', info: data.schedule.timeoftravel },
    { title: 'Repeats', info: data?.schedule?.days.toString() },
    { title: 'Seats', info: `${data.seats} seats` },
    { title: 'Chioce', info: data.choice },
  ];

  const [value, setValue] = React.useState('vehicle');

  const handleChange = (value: string) => {
    setValue(value);
  };

  return (
    <Box p={2}>
      <Typography fontSize='20px' fontWeight={700} mb={2}>
        Edit vehicle details
      </Typography>

      <Typography
        sx={{
          border: value === 'vehicle' ? '1px solid #E4E7EC' : '',
          bgcolor: value === 'vehicle' ? 'white' : '#F9FAFB',
          borderRadius: '8px',
          p: value === 'vehicle' ? '8px 12px' : '',
          width: value === 'vehicle' ? '100%' : '',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          whiteSpace: 'nowrap',
          cursor: 'pointer',
        }}
        fontWeight={600}
        fontSize={14}
        onClick={() => handleChange('vehicle')}
      >
        Edit Vehicle
      </Typography>

      {value === 'vehicle' && (
        <Box>
          <Typography fontSize={12} fontWeight={600} mt={2}>
            Destination
          </Typography>
          <CustomUI from={data?.from} to={data?.to} />
          <Typography fontSize={12} fontWeight={600}>
            Select Vehicle
          </Typography>
          <Box display='grid' gridTemplateColumns='1fr 1fr 1fr' gap={1} my={1}>
            {vehicles.map((item, index) => (
              <Card
                key={index}
                elevation={2}
                sx={
                  selected === index
                    ? {
                        p: 1,
                        bgcolor: '#E8FFF2',
                        position: 'relative',
                      }
                    : {
                        p: 1,
                      }
                }
                onClick={() => onClickCard(index)}
              >
                <img
                  src={item.img}
                  style={{ objectFit: 'contain' }}
                  alt=''
                  height={74}
                  width={74}
                />
                <Typography fontSize={12}>{item.title}</Typography>
                {selected === index ? (
                  <Box
                    position='absolute'
                    top={4}
                    left={4}
                    border='2px solid green'
                    height='20px'
                    width='20px'
                    borderRadius='100%'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Box
                      border='2px solid green'
                      bgcolor='green'
                      height='12px'
                      width='12px'
                      borderRadius='100%'
                    />
                  </Box>
                ) : (
                  ''
                )}
              </Card>
            ))}
          </Box>
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 0.5,
                my: 1,
              }}
            >
              <Typography fontSize={12} fontWeight={600}>
                Model
              </Typography>
              <Typography fontSize={12}>{data?.car_model}</Typography>
            </Box>
            <Divider />
          </Box>
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 0.5,
                my: 1,
              }}
            >
              <Typography fontSize={12} fontWeight={600}>
                License Plate
              </Typography>
              <Typography fontSize={12}>{data?.license_plate}</Typography>
            </Box>
            <Divider />
          </Box>
          <Box mt={2}>
            {someData.map(({ title, info }) => (
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 0.5,
                    my: 1,
                  }}
                >
                  <Typography fontSize={12}>{title}</Typography>
                  <Typography fontSize={12} fontWeight={600}>
                    {info}{' '}
                    <UnfoldMoreIcon
                      sx={{ height: 12, width: 12, color: 'green' }}
                    />
                  </Typography>
                </Box>
                <Divider />
              </Box>
            ))}
          </Box>
          <Typography fontSize={12} mt={4} mb={1}>
            Standard Price
          </Typography>
          <TextField
            disabled
            variant='standard'
            value={data?.price}
            placeholder='NGN'
            InputProps={{ disableUnderline: true }}
            sx={{ borderRadius: '8px', bgcolor: '#F9F9F9', p: 1 }}
            fullWidth
          />
          <Button
            variant='contained'
            sx={{ borderRadius: '100px', mt: 4, p: 1 }}
            fullWidth
          >
            Edit
          </Button>
        </Box>
      )}
      {value === 'driver' && (
        <Box>
          <Typography my={2} fontSize={12} fontWeight={600}>
            Edit Driver Contact
          </Typography>

          <TextField
            variant='standard'
            placeholder='NGN'
            InputProps={{ disableUnderline: true }}
            sx={{ borderRadius: '8px', bgcolor: '#F9F9F9', p: 1 }}
            fullWidth
          />
          <Button
            variant='contained'
            sx={{ borderRadius: '100px', mt: 4, p: 1 }}
            fullWidth
          >
            Update
          </Button>
        </Box>
      )}
    </Box>
  );
};

const vehicles = [
  { title: 'Car', img: Car },
  { title: 'Sienna', img: Sienna },
  { title: 'Small Bus', img: SmallBus },
];

const CustomUI = ({ from, to }: { from: string; to: string }) => {
  return (
    <Box width='100%'>
      <Timeline
        sx={{
          width: '100%',
          p: 0,
          '.MuiTimelineOppositeContent-root': {
            display: 'none',
          },
          '.css-1hqhx8o-MuiTimelineConnector-root': {
            width: '1px',
            m: 0,
            border: '1px dashed #98A2B3',
            bgcolor: '#fff',
          },
          '.css-v6mo1y-MuiTimelineDot-root': {
            m: 0,
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: null,
            bgcolor: '#fff',
            border: '1px solid #E4E7EC',
          },
        }}
      >
        <TimelineItem>
          <TimelineOppositeContent sx={{ flex: 0 }} />
          <TimelineSeparator>
            <TimelineDot>
              <BikeIcon />
            </TimelineDot>
            <TimelineConnector sx={{ border: '1px dashed #98A2B3' }} />
          </TimelineSeparator>
          <TimelineContent>
            <TextField
              fullWidth
              placeholder='From'
              value={from}
              disabled
              variant='standard'
              sx={{ bgcolor: '#F9F9F9', p: 1, borderRadius: '8px', mt: -1 }}
              InputProps={{ disableUnderline: true }}
            />
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent sx={{ flex: 0 }} />
          <TimelineSeparator>
            <TimelineDot>
              <PackageIcon />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <TextField
              fullWidth
              placeholder='To'
              disabled
              value={to}
              variant='standard'
              sx={{ bgcolor: '#F9F9F9', p: 1, borderRadius: '8px', mt: -2 }}
              InputProps={{ disableUnderline: true }}
            />
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Box>
  );
};
