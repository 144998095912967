import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { verifyOtp } from 'services/auth';
import backGroundImage from '../../assets/driverPic.jpeg';
import move9jaLogo from '../../assets/move9ja.svg';

// Validation schema
const schema = yup.object().shape({
  otp: yup.string().required('OTP code is required'),
});

export const Otp = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const mutation = useMutation({
    mutationFn: async (data) => {
      return await verifyOtp(data);
    },
    onSuccess: (data) => {
      // console.log("Succ data", data?.data);
      // console.log("Success data", data?.data?.token);
      if (data?.data?.token) {
        localStorage.setItem('token', data?.data?.token);
        return navigate('/dashboard');
      }
      navigate('/complete-profile', {
        state: { userId: data?.data?.data?.user_id },
      });
    },
    onError: (err) => {},
  });

  const onSubmit = (data: any) => {
    const b = {
      otp: data.otp,
      user_id: (location as any)?.state?.userId,
    };
    mutation.mutate(b as any);
  };

  return (
    <Box height='100vh'>
      <Box
        height='72px'
        p='0px 80px'
        display={{ xs: 'none', md: 'flex' }}
        alignItems='center'
        justifyContent='space-between'
        border='1px solid #E4E7EC'
      >
        <img src={move9jaLogo} alt='' width='162.75px' height='50.26px' />
        <Button
          sx={{
            bgcolor: '#DA4733',
            width: '109px',
            borderRadius: '8px',
            fontWeight: 600,
            fontSize: '14px',
          }}
          variant='contained'
        >
          Register
        </Button>
      </Box>
      <Box
        sx={{
          display: { xs: 'block', md: 'grid' },
          gridTemplateColumns: '40% 60%',
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${backGroundImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left center',
            height: '952px',
            width: '100%',
            margin: 0,
            padding: 0,
            display: { xs: 'none', md: 'grid' },
            placeItems: 'end',
          }}
        >
          <Typography
            color='#fff'
            fontSize='32px'
            fontWeight={600}
            height='92px'
            width='324px'
            mb={15}
            mr={20}
          >
            Request a bus ride, hop in, and go.
          </Typography>
        </Box>
        <Box
          display='flex'
          height={{ md: '100vh' }}
          alignItems='center'
          flexDirection='column'
        >
          <Box
            width={{ xs: '100%', md: '510px' }}
            p='0px 24px'
            mt={{ md: '70px', xs: '40px' }}
          >
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  gap: 2,
                }}
                mb={1}
              >
                <IconButton
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <Typography fontWeight={800} fontSize={20}>
                  Login
                </Typography>
              </Box>
              <Box>
                <Typography mb={4} fontWeight={500}>
                  Enter the OTP sent to your email
                </Typography>

                <form
                  noValidate
                  autoComplete='off'
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <FormControl variant='standard' fullWidth>
                    <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                      OTP code
                    </InputLabel>
                    <Controller
                      name='otp'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder='Paste OTP'
                          variant='standard'
                          InputProps={{ disableUnderline: true }}
                          sx={{
                            bgcolor: '#F9F9F9',
                            mt: 2,
                            mb: 2,
                            py: 1,
                            px: 2,
                            borderRadius: 3,
                            outline: 'none',
                            border: 'none',
                          }}
                          error={!!errors.otp}
                          helperText={errors.otp ? errors.otp.message : ''}
                        />
                      )}
                    />
                  </FormControl>
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    sx={{ fontWeight: 400, p: 1, borderRadius: 2, my: 2 }}
                    type='submit'
                    disabled={mutation.isPending}
                  >
                    {mutation.isPending ? 'Verifying...' : 'Continue'}
                  </Button>
                </form>

                <Typography
                  fontSize={12}
                  fontWeight={500}
                  textAlign='center'
                  mt={1}
                >
                  Resend OTP 0:59
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Otp;
