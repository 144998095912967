import http from 'utils/http';

export const createVehicle = async (body: any) => {
  const res = http.post('/pm/create/createvehicle', body);
  return (await res).data;
};

export const getVehicles = async () => {
  const res = await http.get('/pm/create/vehiclelist');
  return res.data;
};
