import { NavigateNext } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import notAssignedIcon from "assets/not-assigned.svg";
import { useNavigate } from "react-router-dom";

const UnAssignedLoginContent = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/contact-us");
  };
  const NOTASSIGNED_INFO = [
    {
      title: "Chat us via WhatsApp",
      goTo: "/",
    },
    {
      title: "Contact us via email",
      goTo: "/contact-us",
    },
    {
      title: "Frequently asked questions",
      goTo: "/",
    },
    {
      title: "Report a Bug",
      goTo: "/",
    },
  ];
  return (
    <>
      <Box
        height={150}
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "#E8FFF2", width: "100%" }}
      >
        <img src={notAssignedIcon} alt="" width={112.5} height={112.5} />
      </Box>
      <Box p={2}>
        <Typography
          py={1}
          px={1}
          variant="body2"
          fontSize={22}
          textAlign="start"
          fontWeight={700}
        >
          You have not been assigned to a park yet.
        </Typography>
        <Box my={2}>
          {NOTASSIGNED_INFO.map((item) => (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  p: 1,
                }}
                onClick={handleNavigation}
              >
                <Typography fontSize={12}>{item.title}</Typography>
                <NavigateNext fontSize="medium" />
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
        <Box mt={2}>
          <Button
            fullWidth
            sx={{
              borderRadius: "8px",
              p: 1.2,
              backgroundColor: "#FFF5F4",
              color: "red",
              fontWeight: 400,
            }}
            variant="contained"
          >
            Logout
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UnAssignedLoginContent;
