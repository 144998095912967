import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
} from '@mui/material';
import BottomDrawer from 'shared/BottomDrawer';
import { EditDriverContact } from 'shared/EditDriverContact';
import { formatPhoneNumber } from 'utils/misc';
import { useMutation } from '@tanstack/react-query';
import { createDriver } from 'services/driver';
import toast from 'react-hot-toast';
import { handleMutationError } from 'utils/handler';

const schema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  phone: yup
    .string()
    .matches(/^[0-9]+$/, 'Phone number must be digits only')
    .min(10, 'Phone number must be at least 10 digits')
    .required('Phone number is required'),
});

const AddNewDriver = () => {
  const [open, setOpen] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const mutation = useMutation({
    mutationFn: createDriver,
    onSuccess(data) {
      reset({});
      toast.success('Driver created');
    },
    onError(err) {
      handleMutationError(err);
    },
  });

  const onSubmit = (data: any) => {
    const subData = {
      name: data?.firstName + ' ' + data?.lastName,
      phone: formatPhoneNumber(data?.phone),
    };

    mutation.mutate(subData);
  };

  return (
    <>
      <Box p={2}>
        <Box>
          <Box>
            <Avatar sx={{ bgcolor: '#98A2B3', height: 40, width: 40, mb: 2 }}>
              OEssss
            </Avatar>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              gap={2}
            >
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800 }}>
                  First Name
                </InputLabel>
                <Controller
                  name='firstName'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      placeholder='John'
                      variant='standard'
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        bgcolor: '#F9F9F9',
                        mt: 2.5,
                        py: 1,
                        px: 2,
                        borderRadius: '10px',
                      }}
                      type='text'
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                      {...field}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800 }}>
                  Last Name
                </InputLabel>
                <Controller
                  name='lastName'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      placeholder='Doe'
                      type='text'
                      variant='standard'
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        bgcolor: '#F9F9F9',
                        borderRadius: '10px',
                        borderStyle: 'none',
                        mt: 2.5,
                        py: 1,
                        px: 2,
                      }}
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                      {...field}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
              <InputLabel shrink sx={{ fontWeight: 800 }}>
                Phone Number
              </InputLabel>
              <Controller
                name='phone'
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder='9012947382'
                    variant='standard'
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position='start' sx={{ color: '#000' }}>
                          +234
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      bgcolor: '#F9F9F9',
                      mt: 2.5,
                      mb: 2,
                      py: 1,
                      px: 2,
                      borderRadius: '10px',
                    }}
                    type='text'
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <Button
              disabled={!!mutation.isPending}
              sx={{ borderRadius: '100px', fontWeight: 400 }}
              variant='contained'
              fullWidth
              type='submit'
            >
              {mutation?.isPending ? 'Loading...' : 'Save'}
            </Button>
          </form>
        </Box>
      </Box>
      <BottomDrawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <EditDriverContact />
      </BottomDrawer>
    </>
  );
};

export default AddNewDriver;
