type ScheduleType = 'recurring' | 'specific';

interface Schedule {
  type: ScheduleType;
  days?: string[]; // Only for recurring schedules
  date?: string; // Only for specific schedules
  timeoftravel: string;
}

interface InputData {
  from: string;
  to: string;
  seats: number;
  license_plate: string;
  vehicle: string;
  choice: string;
  price: number;
  estimatedTravelTime: number;
  stop_park: boolean;
  repeat: string;
  selectedDays?: string[];
  timeOfTravel: string;
  dateOfTravel?: string;
  front_photo: string;
  back_photo: string;
}

interface OutputData {
  from: string;
  to: string;
  seats: string;
  license_plate: string;
  car_model: string;
  choice: string;
  car_type: string;
  price: number;
  estimated_hours: number;
  stop_park: boolean;
  schedule: Schedule;
  front_photo: string;
  back_photo: string;
}

export function convertObject(input: InputData): OutputData {
  const base64EncodeImage = (filePath: string): string => {
    // This is a placeholder for converting a file path to a Base64 string.
    // Replace this with actual Base64 conversion logic if needed.
    return 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/...';
  };

  const convertSchedule = (
    repeat: string,
    selectedDays: string[] | undefined,
    timeOfTravel: string,
    dateOfTravel: string | undefined
  ): Schedule => {
    if (repeat === 'Recurring') {
      return {
        type: 'recurring',
        days: selectedDays,
        timeoftravel: new Date(timeOfTravel).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        }),
      };
    } else {
      return {
        type: 'specific',
        date: dateOfTravel,
        timeoftravel: new Date(timeOfTravel).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        }),
      };
    }
  };

  const output: OutputData = {
    from: input.from,
    to: input.to,
    seats: String(input.seats),
    license_plate: input.license_plate,
    car_model: input.vehicle,
    choice: input.choice,
    car_type: input.vehicle,
    price: input.price,
    estimated_hours: +input.estimatedTravelTime,
    stop_park: input.stop_park,
    schedule: convertSchedule(
      input.repeat,
      input.selectedDays,
      input.timeOfTravel,
      input.dateOfTravel
    ),
    front_photo: base64EncodeImage(input.front_photo),
    back_photo: base64EncodeImage(input.back_photo),
  };

  return output;
}
