import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import BottomDrawer from "shared/BottomDrawer";
import { useState } from "react";
import { FeedbackRecieved } from "./Feedback";

export const CompleteProfileVerification = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <BottomDrawer open={open} onClose={() => setOpen(false)}>
        <FeedbackRecieved />
      </BottomDrawer>
      <Box p={2}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 2,
          }}
          mb={1}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography fontWeight={800} fontSize={20}>
            Complete Profile
          </Typography>
        </Box>
        <Box>
          <Typography mb={4} fontWeight={500}>
            verify your identity
          </Typography>

          <FormControl variant="standard" fullWidth>
            <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
              Select verification Type
            </InputLabel>
            <Autocomplete
              disablePortal
              id="verify"
              options={verifyType}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  sx={{
                    bgcolor: "#F9F9F9",
                    mt: 3,
                    px: 2,
                    py: 1,
                    borderRadius: 3,
                    outline: "none",
                    border: "none",
                  }}
                />
              )}
            />
          </FormControl>

          <FormControl sx={{ mt: 3 }} variant="standard" fullWidth>
            <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
              Enter you NIN
            </InputLabel>
            <TextField
              placeholder="Enter your NIN 10 digits no"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              sx={{
                bgcolor: "#F9F9F9",
                mt: 3,
                mb: 2,
                borderRadius: 3,
                outline: "none",
                border: "none",
                px: 2,
                py: 1,
              }}
            />
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ fontWeight: 400, p: 1, borderRadius: 2, my: 2 }}
            onClick={() => {
              setOpen(true);
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </>
  );
};

const verifyType = [
  { label: "NIN" },
  { label: "Voter's Card" },
  { label: "BVN" },
  { label: "Passport" },
];
