import React, { useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import BottomDrawer from './BottomDrawer';
import SetTravellersDetail from './BottomDrawerContent/SetTravellersDetail';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createDriver } from 'services/driver';
import toast from 'react-hot-toast';
import { handleMutationError } from 'utils/handler';
import { getAllbanks, verifyBankAccount } from 'services/bank';

// Define the validation schema
const schema = yup.object().shape({
  phone: yup.string().required('Phone number is required'),
  bank_name: yup.string().required('Bank name is required'),
  account_number: yup
    .string()
    .matches(/^\d{10}$/, 'Bank account must be exactly 10 digits')
    .required('Bank account is required'),
});

export const EditDriverContact = () => {
  const { data } = useQuery({
    queryKey: ['GET_ALL_BANKS'],
    queryFn: getAllbanks,
  });

  const banks = data?.data?.banks || [];

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const bankAccount = watch('account_number');
  const bankName = watch('bank_name');

  const [open, setOpen] = React.useState(false);

  const mutation = useMutation({
    mutationFn: verifyBankAccount,
    onSuccess: ({ data }) => {},
    onError: (err) => {
      handleMutationError(err);
    },
  });

  const resp = mutation.data;

  const driverMutation = useMutation({
    mutationFn: createDriver,
    onSuccess(data) {
      toast.success('Driver created');

      reset({});
      // setOpen(true);
    },
    onError(err) {
      handleMutationError(err);
      reset({});
    },
  });
  const submitData = {
    name: resp?.data?.result?.name,
    phone: watch('phone'),
    bank_code: bankName,
    bank_name: banks?.find((x: any) => x?.code === bankName)?.name,
    account_number: bankAccount,
  };

  const onSubmit = () => {
    driverMutation.mutate({
      name: submitData.name,
      phone: submitData.phone,
      bank_code: submitData.bank_code,
      bank_name: submitData.bank_name,
      account_number: submitData.account_number,
    });
  };
  useEffect(() => {
    if (bankAccount?.length === 10 && bankName) {
      mutation.mutate({ code: bankName, account_number: bankAccount });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccount, bankName]);

  return (
    <>
      <Box p={2}>
        <Box>
          <Box>
            <Typography mb={2} fontWeight={700}>
              Create Driver Contact
            </Typography>
          </Box>

          <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink sx={{ fontWeight: 800 }}>
              Phone Number
            </InputLabel>
            <Controller
              name='phone'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder='123-456-7890'
                  variant='standard'
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    bgcolor: '#F9F9F9',
                    mt: 2.5,
                    mb: 2,
                    py: 1,
                    px: 2,
                    borderRadius: '10px',
                  }}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                />
              )}
            />
          </FormControl>
          <FormControl variant='standard' fullWidth>
            <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
              Bank Name
            </InputLabel>
            <Controller
              name='bank_name'
              control={control}
              defaultValue={undefined}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={banks}
                  getOptionLabel={(option: any) => option.name}
                  isOptionEqualToValue={(option: any, value) =>
                    option.code === value.code
                  }
                  value={
                    banks.find((bank: any) => bank.code === field.value) || null
                  }
                  onChange={(_, data) => field.onChange(data ? data.code : '')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='standard'
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      placeholder='Select Bank Name'
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                            p: 0,
                          },
                        },
                        '& .css-1023f6h-MuiInputBase-root-MuiInput-root::before':
                          {
                            borderBottom: '0px solid red',
                          },
                        bgcolor: '#F9F9F9',
                        mt: 3,
                        px: 2,
                        py: 1,
                        borderRadius: 3,
                        outline: 'none',
                        border: 'none',
                      }}
                      error={!!errors.bank_name}
                      helperText={
                        errors.bank_name ? errors.bank_name.message : ''
                      }
                    />
                  )}
                />
              )}
            />
          </FormControl>
          <FormControl sx={{ mt: 2 }} variant='standard' fullWidth>
            <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
              Bank account
            </InputLabel>
            <Controller
              name='account_number'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder='Enter your 10 digits bank account number'
                  variant='standard'
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    bgcolor: '#F9F9F9',
                    p: 1,
                    mt: 2,
                    mb: 2,
                    borderRadius: 3,
                    outline: 'none',
                    border: 'none',
                  }}
                  error={!!errors.account_number}
                  helperText={
                    errors.account_number ? errors.account_number.message : ''
                  }
                />
              )}
            />
          </FormControl>
          {mutation.isPending && (
            <Box display='flex' alignItems='center' justifyContent='center'>
              <CircularProgress />
            </Box>
          )}
          {resp?.data?.result?.name && bankAccount?.length === 10 && (
            <>
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 4 }}>
                  Account Name
                </InputLabel>
                <TextField
                  disabled
                  value={resp?.data?.result?.name}
                  variant='standard'
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                  sx={{
                    bgcolor: '#F9F9F9',
                    p: 1,
                    mb: 2,
                    borderRadius: 3,
                    outline: 'none',
                    border: 'none',
                  }}
                />
              </FormControl>
            </>
          )}

          {bankAccount?.length === 10 && resp?.data?.result?.name && (
            <Button
              sx={{ borderRadius: '100px', fontWeight: 400 }}
              disabled={!!driverMutation.isPending}
              variant='contained'
              fullWidth
              onClick={handleSubmit(onSubmit)}
            >
              {driverMutation.isPending ? 'Loading...' : 'Save'}
            </Button>
          )}
        </Box>
      </Box>
      <BottomDrawer open={open} onClose={() => setOpen(false)}>
        <SetTravellersDetail />
      </BottomDrawer>
    </>
  );
};
