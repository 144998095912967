import React from "react";
import useCalendar from "hooks/useCalendar";
import { format, isToday } from "date-fns";
import { IconButton, Typography } from "@mui/material";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const Calendar = () => {
  const { currentMonth, days, goToPreviousDays, goToNextDays } = useCalendar();

  return (
    <div style={{ color: "white", marginTop: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <IconButton sx={{ color: "#fff" }} onClick={goToPreviousDays}>
          <NavigateBeforeIcon />
        </IconButton>
        <Typography style={{ color: "#fff" }}>{currentMonth}</Typography>
        <IconButton sx={{ color: "#fff" }} onClick={goToNextDays}>
          <NavigateNextIcon />
        </IconButton>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {days.map((date: Date) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              color={isToday(date) ? "#fff" : "#98A2B3"}
              fontSize="12px"
            >
              {isToday(date) ? "Today" : format(date, "EEE")}
            </Typography>
            <div
              key={date.toString()}
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: isToday(date) ? "green" : "transparent",
                textAlign: "center",
                lineHeight: "40px",
                margin: "5px",
                color: isToday(date) ? "#fff" : "gray",
                border: isToday(date) ? "" : "1px solid gray",
              }}
            >
              <Typography fontWeight={600}>{format(date, "d")}</Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
