import Activities from 'pages/Activities';
import ContactUs from 'pages/ContactUs';
import { Profile } from 'pages/Profile';
import ProfileDetails from 'pages/ProfileDetails';
import Schedule from 'pages/Schedule';
import Trips from 'pages/Trips';
import { AddBank } from 'pages/auth/AddBank';
import { CompleteProfile } from 'pages/auth/CompleteProfile';
import { Landing } from 'pages/auth/Landing';
import { Login } from 'pages/auth/Login';
import ProfileCompleted from 'pages/auth/ProfileCompleted';
import { SplashScreen } from 'pages/auth/SplashScreen';
import { Otp } from 'pages/auth/otp';
import { CreateDriver } from 'pages/CreateDriver';
import CreateVehicle from 'pages/vehicles/CreateVehicle';
import { Routes as BrowserRoutes, Navigate, Route } from 'react-router-dom';

import { Fragment } from 'react/jsx-runtime';
import AuthGuard from 'routes/AuthGuard';
import { Wallet } from 'pages/Wallet';
import { Cards } from 'pages/Cards';
import { EarnigsBreakdown } from 'pages/EarningsBreakdown';
import Messages from 'pages/Messages';
import Details from 'pages/Details';
import Verification from 'pages/Verification';
import Payouts from 'pages/Payouts';
import Preferences from 'pages/Preferences';
import TravelDetails from 'pages/TravelDetails';
import Search from 'pages/Search';
import { AddNewVehicle } from 'pages/AddNewVehicle';
import AddOn from 'pages/AddOn';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import ResponsiveComponent from 'pages/vehicles/Re';
import { useAppContext } from 'context/chatProvider';
import useSocket from 'hooks/useSocket';
import { useEffect } from 'react';

interface RouteConfig {
  path: string;
  element: JSX.Element;
  hasAuth?: boolean;
}

const ROUTES: RouteConfig[] = [
  {
    path: '/',
    element: !localStorage.getItem('token') ? (
      <SplashScreen />
    ) : (
      <Navigate to='/dashboard' />
    ),
  },
  {
    path: '/landing',
    element: <Landing />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/otp',
    element: <Otp />,
  },
  {
    path: '/complete-profile',
    element: <CompleteProfile />,
  },
  {
    path: '/add-bank',
    element: <AddBank />,
  },
  {
    path: '/profile-completed',
    element: <ProfileCompleted />,
  },
  {
    path: '/re',
    element: <ResponsiveComponent />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },

  {
    path: '/dashboard',
    element: <Schedule />,
    hasAuth: true,
  },
  {
    path: '/trips',
    element: <Trips />,
    hasAuth: true,
  },
  {
    path: '/create-vehicle',
    element: <CreateVehicle />,
    hasAuth: true,
  },
  {
    path: '/create-driver',
    element: <CreateDriver />,
    hasAuth: true,
  },
  {
    path: '/wallet',
    element: <Wallet />,
    hasAuth: true,
  },
  {
    path: '/verification',
    element: <Verification />,
  },
  {
    path: '/cards',
    element: <Cards />,
    hasAuth: true,
  },
  {
    path: '/activities',
    element: <Activities />,
  },
  {
    path: '/contact-us',
    element: <ContactUs />,
  },
  {
    path: '/profile',
    element: <Profile />,
    hasAuth: true,
  },
  {
    path: '/profile-details',
    element: <ProfileDetails />,
    hasAuth: true,
  },
  {
    path: '/contact-us',
    element: <ContactUs />,
  },
  {
    path: '/earnings-breakdown',
    element: <EarnigsBreakdown />,
    hasAuth: true,
  },
  {
    path: '/messages/:id',
    element: <Messages />,
    hasAuth: true,
  },
  {
    path: '/details',
    element: <Details />,
    hasAuth: true,
  },
  {
    path: '/payouts',
    element: <Payouts />,
    hasAuth: true,
  },
  {
    path: '/preferences',
    element: <Preferences />,
    hasAuth: true,
  },
  {
    path: '/travellers-details',
    element: <TravelDetails />,
    hasAuth: true,
  },
  {
    path: '/search',
    element: <Search />,
    hasAuth: true,
  },
  {
    path: '/add-new-vehicle',
    element: <AddNewVehicle />,
    hasAuth: true,
  },
  {
    path: '/add-on',
    element: <AddOn />,
    hasAuth: true,
  },
];

const AppRoute = () => {
  const { socket } = useSocket();

  const { state, dispatch } = useAppContext();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      socket?.on('userRooms', (e) => {
        dispatch({ type: 'SET_ROOMS', payload: e });
      });

      socket?.on('roomMessages', (e) => {
        console.log('Room messages', e);
        dispatch({ type: 'SET_MESSAGES', payload: e.messages });
      });

      socket?.on('receiveMessage', (e) => {
        if (e.newMessage.sender[0] !== state.user?._id) {
          // Show toast for new message
        }
        dispatch({ type: 'ADD_MESSAGE', payload: e.newMessage });
      });

      socket?.on('allNotification', (e) => {
        dispatch({ type: 'SET_NOTIFICATIONS', payload: e });
      });

      socket?.on('notification', (e) => {
        dispatch({ type: 'ADD_NOTIFICATION', payload: e });
      });
    }

    return () => {
      socket?.off('userRooms');
      socket?.off('roomMessages');
      socket?.off('receiveMessage');
      socket?.off('allNotification');
      socket?.off('notification');
    };
  }, [socket, dispatch, state.user]);
  return (
    <>
      <BrowserRoutes>
        {ROUTES.map((route) => {
          const { hasAuth } = route;
          const Parent = hasAuth ? AuthGuard : Fragment;
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<Parent>{route.element}</Parent>}
            />
          );
        })}
      </BrowserRoutes>
    </>
  );
};

export default AppRoute;
