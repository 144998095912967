import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { verifyKyc } from "services/auth";
import toast from "react-hot-toast";
import move9jaLogo from "../../assets/move9ja.svg";
import { handleMutationError } from "utils/handler";

// Validation schema
const schema = yup.object().shape({
  id_type: yup
    .string()
    .oneOf(["NIN_V2", "BVN", "NIN_SLIP", ""], "Invalid ID Type")
    .required("ID Type is required"),
  id_number: yup
    .string()
    .matches(/^\d{11}$/, "ID number must be exactly 11 digits")
    .required("ID number is required"),
});

const IDType = [
  { value: "NIN_V2", label: "NIN V2" },
  { value: "BVN", label: "BVN" },
  { value: "NIN_SLIP", label: "NIN Slip" },
];

interface FormData {
  id_type: string;
  id_number: string;
}

export const CompleteProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location?.state?.userId;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const mutation = useMutation({
    mutationFn: async (data) => {
      return await verifyKyc(data);
    },
    onSuccess: (data) => {
      navigate("/add-bank", { state: { userId } });
    },
    onError: (err: any) => {
      handleMutationError(err);
    },
  });

  const onSubmit = (data: FormData) => {
    const formData = {
      user_id: userId,
      id_type: data.id_type,
      id_number: data.id_number,
    };
    mutation.mutate(formData as any);
  };

  return (
    <Box height="100vh">
      <Box
        height="72px"
        p="0px 80px"
        display={{ xs: "none", md: "flex" }}
        alignItems="center"
        justifyContent="space-between"
        border="1px solid #E4E7EC"
      >
        <img src={move9jaLogo} alt="" width="162.75px" height="50.26px" />
        <Button
          sx={{
            bgcolor: "#DA4733",
            width: "109px",
            borderRadius: "8px",
            fontWeight: 600,
            fontSize: "14px",
          }}
          variant="contained"
        >
          Register
        </Button>
      </Box>
      <Box
        sx={{
          display: "grid",
          mx: "auto",
        }}
      >
        <Box
          display="flex"
          height={{ md: "100vh" }}
          alignItems="center"
          flexDirection="column"
        >
          <Box
            width={{ xs: "100%", md: "510px" }}
            p="0px 24px"
            mt={{ md: "70px", xs: "40px" }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: 2,
                }}
                mb={1}
              >
                <IconButton
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <Typography fontWeight={800} fontSize={20}>
                  Complete Profile
                </Typography>
              </Box>
              <Box>
                <Typography mb={4} fontWeight={500}>
                  Verify your identity
                </Typography>

                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <FormControl
                    variant="standard"
                    fullWidth
                    error={!!errors.id_type}
                  >
                    <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                      Select verification Type
                    </InputLabel>
                    <Controller
                      name="id_type"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select
                          {...field}
                          displayEmpty
                          variant="standard"
                          inputProps={{ disableUnderline: true }}
                          disableUnderline
                          sx={{
                            bgcolor: "#F9F9F9",
                            mt: 2,
                            mb: 2,
                            py: 1,
                            px: 2,
                            borderRadius: 3,
                            outline: "none",
                            border: "none",
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select verification Type
                          </MenuItem>
                          {IDType.map((type) => (
                            <MenuItem key={type.value} value={type.value}>
                              {type.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    <Typography color="error">
                      {errors.id_type?.message}
                    </Typography>
                  </FormControl>

                  <FormControl
                    sx={{ mt: 3 }}
                    variant="standard"
                    fullWidth
                    error={!!errors.id_number}
                  >
                    <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                      Enter your NIN
                    </InputLabel>
                    <Controller
                      name="id_number"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Enter your NIN 11 digits no"
                          variant="standard"
                          InputProps={{ disableUnderline: true }}
                          sx={{
                            bgcolor: "#F9F9F9",
                            mt: 3,
                            mb: 2,
                            borderRadius: 3,
                            outline: "none",
                            border: "none",
                            px: 2,
                            py: 1,
                          }}
                          error={!!errors.id_number}
                          helperText={
                            errors.id_number ? errors.id_number.message : ""
                          }
                        />
                      )}
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ fontWeight: 400, p: 1, borderRadius: 2, my: 2 }}
                    type="submit"
                    disabled={mutation.isPending}
                  >
                    {mutation.isPending ? "Verifying..." : "Continue"}
                  </Button>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompleteProfile;
