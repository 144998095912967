import { ArrowBack } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import Move9jaCard from 'shared/Move9jaCard';

const Details = () => {
  const navigate = useNavigate();
  return (
    <Box height='100vh'>
      <Box p={2}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={700} fontSize={20}>
              Details
            </Typography>
          </Box>
        </Box>
        <Move9jaCard marginTop={2} title="Traveller's Details">
          <Box p='12px'>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='flex-start'
              gap={1}
              py='9px'
            >
              <Avatar sx={{ bgcolor: '#EE46BC', color: 'white' }}>OE</Avatar>
              <Typography fontSize='12px' fontWeight={600}>
                Otega Ese
              </Typography>
            </Box>
            <Divider />
            <Box pt='9px'>
              <Box
                display='flex'
                alignContent='center'
                justifyContent='space-between'
                mb='8px'
              >
                <Typography color='#667085' fontWeight={400} fontSize={12}>
                  Phone number
                </Typography>
                <Typography fontWeight={400} fontSize={12}>
                  08939749493983
                </Typography>
              </Box>
              <Box
                display='flex'
                alignContent='center'
                justifyContent='space-between'
              >
                <Typography color='#667085' fontWeight={400} fontSize={12}>
                  Email
                </Typography>
                <Typography fontWeight={400} fontSize={12}>
                  brownskin@gmail.com
                </Typography>
              </Box>
            </Box>
          </Box>
        </Move9jaCard>
        <Move9jaCard marginTop={1} title='Add Ons'>
          <Box p='12px'>
            <Typography fontSize='12px' fontWeight={600} pb='8px'>
              Luggage Space
            </Typography>

            <Typography color='#667085' fontWeight={400} fontSize={12} pb='8px'>
              Save boot space for your ... (added)
            </Typography>
            <Typography fontWeight={600} color='#3E7EFF' fontSize={12} pb='8px'>
              + NGN 2,000
            </Typography>

            <Typography
              fontWeight={600}
              color='#0085FF'
              fontSize={12}
              textAlign='center'
            >
              View more (3)
            </Typography>
          </Box>
        </Move9jaCard>
        <Move9jaCard marginTop={1} title='Payment method'>
          <Box p='12px'>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              gap={1}
              p='9px'
            >
              <Typography fontSize='12px' fontWeight={600}>
                Pay with Cash
              </Typography>
              <Typography fontSize='12px' fontWeight={600}>
                NGN 235,895
              </Typography>
            </Box>
            <Divider />
            <Typography
              color='#379E66'
              fontWeight={400}
              fontSize={12}
              p='8px 8px 0px 8px'
            >
              Confirmed
            </Typography>
          </Box>
        </Move9jaCard>
        <Typography fontSize={12} py='8px'>
          Did something happen?
        </Typography>
        <Button
          sx={{
            fontWeight: 600,
            fontSize: 12,
            border: '1px solid #E4E7EC',
            borderRadius: '8px',
            color: 'red',
            p: '12px 6px 12px 6px',
            bgcolor: 'transparent',
            mb: '8px',
          }}
          variant='contained'
          fullWidth
        >
          Emergency
        </Button>
        <Button
          sx={{
            fontWeight: 600,
            fontSize: 12,
            border: '1px solid #E4E7EC',
            borderRadius: '8px',
            color: '#98A2B3',
            p: '12px 6px 12px 6px',
            bgcolor: 'transparent',
          }}
          variant='contained'
          fullWidth
        >
          Contact Next of KIN
        </Button>
      </Box>
    </Box>
  );
};

export default Details;
