import { Box, Typography } from "@mui/material";

import { ReactComponent as OrderArrow } from "assets/order-arrow.svg";

const TripCard = ({
  onClick,
  from,
  to,
  start,
  stop,
  status,
}: {
  onClick?: () => void;
  from?: string;
  to?: string;
  start?: string;
  stop?: string;
  status?: string;
}) => {
  return (
    <Box
      onClick={onClick}
      my={1}
      mx={1}
      p={2}
      border="1px solid #E4E7EC"
      boxShadow="0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A"
      borderRadius="8px"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="12px" textAlign="left">
          {start ? start : "Young Legacy Park, Iyana-Ipaja"}
        </Typography>
        <OrderArrow />
        <Typography fontSize="12px" textAlign="right">
          {stop ? stop : " Abukbakar Gamjuma Market Park"}
        </Typography>
      </Box>
      <Box
        display="flex"
        mt={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography fontSize="12px" color="#98A2B3">
          {from ? from : "Lagos"}
        </Typography>
        <Typography fontSize="12px" color="#98A2B3">
          {to ? to : "Illorin"}
        </Typography>
      </Box>

      <Box mt={1} display="flex" gap={1} alignItems="center">
        <Typography
          fontWeight={600}
          bgcolor="#ECFDF3"
          padding="2px 8px 2px 6px"
          fontSize="12px"
          color="#3538CD"
          borderRadius="16px"
          display="flex"
          gap={1}
          alignItems="center"
        >
          <Box height="8px" width="8px" borderRadius="50%" bgcolor="#379E66" />
          <span style={{ color: "#12B76A" }}> {status}</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default TripCard;
