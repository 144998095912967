import { ArrowUpward } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { ReactNode, useState } from 'react';
import Move9jaCard from './Move9jaCard';
import CustomTimeline from './TravelLocationTimeline';
import TravelerDetails from './TravelerDetails';
import { Button } from '@mui/material';
import Car from '../assets/car.png';
import BottomDrawer from './BottomDrawer';
import TripConcluded from 'shared/TripConcluded';
import ReasonsForDecline from './BottomDrawerContent/ReasonsForDecline';
import { calculateTimeDifference } from 'pages/Trips';
import { updateTripStatus } from 'services/booking';
import { useMutation } from '@tanstack/react-query';

const TripOrderContent = ({ tripData, status }: any) => {
  const [open, setOpen] = React.useState(false);
  const [openDecline, setOpenDecline] = React.useState(false);

  const { completed } = tripData;

  const updateStatusMutation = useMutation({
    mutationFn: async (data: any) => {
      return await updateTripStatus(data);
    },
    onSuccess: (data) => {
      console.log('UPDATE STAT', data);
      setOpen(true);
    },
    onError: (err) => {},
  });

  const tripId = tripData.trip_id;

  const onSubmitData = (tripId: string) => {
    const data = {
      trip_id: tripId,
      status: 'completed',
    };

    updateStatusMutation.mutate(data);
  };
  if (status === 'Completed') {
    return <TripConcluded tripData={tripData} />;
  }
  return (
    <>
      <Box px={2}>
        <Typography mt={2} fontSize='24px' fontWeight={700}>
          Order: {tripData.order_id}
        </Typography>
        <Typography my={1} fontSize='12px' color='#98A2B3' fontWeight={400}>
          Travel ID; {tripData.trip_id}
        </Typography>
        <Typography
          width='84px'
          fontWeight={600}
          px={2}
          sx={{
            bgcolor: '#F0F9FF',
            color: '#026AA2',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '16px',
          }}
        >
          <ArrowUpward sx={{ color: '#0BA5EC', height: 12, width: 12 }} />
          <span
            style={{
              fontSize: '12px',
              whiteSpace: 'nowrap',
              textTransform: 'capitalize',
            }}
          >
            {tripData.trip_status}
          </span>
        </Typography>

        <ReUseAbleTripCards
          title='Ongoing'
          bgcolor='#379E66'
          color='#fff'
          subtitle={calculateTimeDifference(
            tripData.starttime,
            tripData.endtime
          )}
        >
          <CustomTimeline
            from={tripData.from}
            to={tripData.to}
            start_park={tripData.parkbus_stop}
            start_time={tripData.starttime}
            stop_park={tripData.stop_park}
            stop_time={tripData.endtime}
          />
        </ReUseAbleTripCards>
        {tripData?.driver?.name && (
          <ReUseAbleTripCards title='Bus Details'>
            <Box display='grid' gridTemplateColumns='1fr 1fr 1fr' gap={1} p={1}>
              <img src={Car} alt='' height={74} width={74} />
              <Box fontSize={12}>
                <Typography fontSize={12}>Vehicle</Typography>
                <Typography fontSize={12} fontWeight={600}>
                  {tripData.car_model}
                </Typography>
                <Typography fontSize={12}>Driver</Typography>
                <Typography
                  sx={{
                    textTransform: 'capitalize',
                  }}
                  fontWeight={600}
                  fontSize={12}
                >
                  Mr {tripData?.driver?.name}
                </Typography>
              </Box>
              <Box display='flex' alignItems='flex-end' flexDirection='column'>
                <Typography fontSize={12}>License Plate</Typography>
                <Typography fontSize={12} fontWeight={600}>
                  {' '}
                  {tripData.license_plate}
                </Typography>
                <Typography fontSize={12}>Phone</Typography>
                <Typography fontSize={12} fontWeight={600}>
                  {tripData?.driver?.phone}
                </Typography>
              </Box>
            </Box>
          </ReUseAbleTripCards>
        )}
        <Move9jaCard title="Traveller's Details">
          <Box m={1}>
            {tripData.passengers.map((pass: any) => (
              <TravelerDetails
                amount={pass.pass.amount}
                name={pass.pass.fullname}
                payMtd={pass.pass.paymentType}
                phone={pass.pass.phoneNumber}
                seatNumber={pass.pass.selected_seat}
              />
            ))}
          </Box>
        </Move9jaCard>

        {!completed && (
          <Box mt={1}>
            <Button
              fullWidth
              sx={{ borderRadius: '100px' }}
              variant='contained'
              onClick={() => {
                onSubmitData(tripId);
              }}
            >
              Accept
            </Button>
          </Box>
        )}
        {!completed && (
          <Box mt={1}>
            <Button
              fullWidth
              sx={{
                borderRadius: '100px',
                bgcolor: '#F2F4F7',
                color: '#475467',
              }}
              variant='contained'
              onClick={() => setOpenDecline(true)}
            >
              Decline
            </Button>
          </Box>
        )}
      </Box>
      <BottomDrawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <TripConcluded tripData={tripData} />
      </BottomDrawer>
      <BottomDrawer
        open={openDecline}
        onClose={() => {
          setOpenDecline(false);
        }}
      >
        <ReasonsForDecline />
      </BottomDrawer>
    </>
  );
};
export default TripOrderContent;

export const ReUseAbleTripCards = ({
  title,
  subtitle,
  bgcolor = '#F6F6F6',
  children,
  color = '#000',
  onClick,
}: {
  title: string;
  children: ReactNode;
  subtitle?: string;
  bgcolor?: string;
  color?: string;
  onClick?: () => void;
}) => {
  return (
    <Box
      border='1px solid #E4E7EC'
      borderRadius='8px'
      mt={3}
      overflow='hidden'
      onClick={onClick}
      sx={{ cursor: 'pointer' }}
    >
      <Box
        bgcolor={bgcolor}
        color={color}
        p={1}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography fontSize='12px' fontWeight={400}>
          {title}
        </Typography>
        <Typography fontSize='12px' fontWeight={400}>
          {subtitle}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};
