import http from "utils/http";

export const getAllbanks = async () => {
  const res = http.get("/pm/auth/allbanks");
  return (await res).data;
};

export const verifyBankAccount = async (body: any) => {
  const res = http.post("/pm/auth/verifyaccount", body);
  return (await res).data;
};
