import CheckCircleOutline from "../../assets/check-circle.svg";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import move9jaLogo from "../../assets/move9ja.svg";
const ProfileCompleted = () => {
  const navigate = useNavigate();
  return (
    <Box height="100vh">
      <Box
        height="72px"
        p="0px 80px"
        display={{ xs: "none", md: "flex" }}
        alignItems="center"
        justifyContent="space-between"
        border="1px solid #E4E7EC"
      >
        <img src={move9jaLogo} alt="" width="162.75px" height="50.26px" />
        <Button
          sx={{
            bgcolor: "#DA4733",
            width: "109px",
            borderRadius: "8px",
            fontWeight: 600,
            fontSize: "14px",
          }}
          variant="contained"
        >
          Register
        </Button>
      </Box>
      <Box
        sx={{
          display: "grid",
          mx: "auto",
        }}
      >
        <Box
          display="flex"
          height={{ md: "100vh" }}
          alignItems="center"
          flexDirection="column"
        >
          <Box
            width={{ xs: "100%", md: "510px" }}
            p="0px 24px"
            mt={{ md: "40px", xs: "40px" }}
          >
            <Box
              sx={{
                height: "100vh",
                width: "100wh",
                position: "absolute",
                top: 0,
                backgroundColor: "white",
                display: "grid",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "column",
              }}
              p={2}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                gap={2}
              >
                <img
                  src={CheckCircleOutline}
                  style={{ height: 64, width: 64 }}
                />
                <Typography fontWeight={600} fontSize={20}>
                  Sign up completed!
                </Typography>
                <Typography
                  px={4}
                  textAlign="center"
                  fontWeight={500}
                  fontSize={14}
                >
                  Welcome Jonnyboy D, You’ve completed your registration
                </Typography>
              </Box>
              <Button
                sx={{
                  color: "#000",
                  bgcolor: "#F2F4F7",
                  borderRadius: "8px",
                  p: "14px",
                }}
                variant="contained"
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Go to Dashboard
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileCompleted;
