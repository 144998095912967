import { Checkbox } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import VerificationImage from 'assets/verification.png';
const LevelUpAccount = () => {
  return (
    <>
      <Box
        height={200}
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={{ backgroundColor: '#E8FFF2' }}
      >
        <img
          src={VerificationImage}
          style={{ objectFit: 'contain' }}
          alt=''
          width={244}
          height={200}
        />
      </Box>
      <Box p={2}>
        <Typography
          py={1}
          variant='body2'
          fontSize={22}
          textAlign='left'
          fontWeight={700}
        >
          Level Up your account by verifying your identity.
        </Typography>
        <Typography fontSize={12} sx={{ textDecoration: 'underline' }}>
          What is required
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox />
          <Typography fontSize={12}>Account upgrade (level 2)</Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox />
          <Typography fontSize={12}>
            You will be charged $4.00 (NGN 5,900.00)
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox />
          <Typography fontSize={12}>
            Your card with $1 (NGN 1,340.00)
          </Typography>
        </div>
        <Button
          disableElevation
          fullWidth
          sx={{
            p: '12px 24px',
            borderRadius: '8px',
            color: '#fff',
            fontWeight: 400,
            mt: 8,
          }}
          variant='contained'
        >
          Start verification
        </Button>
      </Box>
    </>
  );
};

export default LevelUpAccount;
