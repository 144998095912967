import { ArrowBack, Close } from "@mui/icons-material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SearchIcon } from "assets/search-icon.svg";

const Search = () => {
  const navigate = useNavigate();
  return (
    <Box>
      {" "}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: 2,
        }}
        m={2}
      >
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography fontWeight={800} fontSize={20}>
          Search
        </Typography>
      </Box>
      <Box px={2}>
        <TextField
          placeholder="Search Plates No, order ID, Transaction..."
          variant="standard"
          InputProps={{
            disableUnderline: true,
            startAdornment: <SearchIcon />,
          }}
          fullWidth
          inputProps={{
            sx: {
              "&::placeholder": {
                p: 1,
                mt: 1.5,
              },
            },
          }}
          sx={{
            bgcolor: "#F9F9F9",
            mt: 2,
            border: "none",
            p: 1,
            px: 2,
            borderRadius: "8px",
          }}
        />
        <Box>
          <Typography my={2}>History</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: "12px",
              borderRadius: "8px",
              border: "1px solid #E4E7EC",
            }}
            mb={1}
          >
            <Typography fontSize={12} fontWeight={600}>
              {" "}
              LAGOS
            </Typography>
            <Close fontSize="small" />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: "12px",
              borderRadius: "8px",
              border: "1px solid #E4E7EC",
            }}
            mb={1}
          >
            <Typography fontSize={12} fontWeight={600}>
              {" "}
              ABUJA
            </Typography>
            <Close fontSize="small" />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: "12px",
              borderRadius: "8px",
              border: "1px solid #E4E7EC",
            }}
          >
            <Typography fontSize={12} fontWeight={600}>
              {" "}
              PORTHARCOURT
            </Typography>
            <Close fontSize="small" />
          </Box>
        </Box>
        <Typography my={2}>Results</Typography>
        <Box sx={{ border: "1px solid #E4E7EC", borderRadius: "8px" }}>
          {[1, 2, 3].map((_) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                p: "12px",
              }}
              mb={1}
            >
              <LocationOnOutlinedIcon />
              <Box>
                <Typography fontSize={12} fontWeight={600}>
                  {" "}
                  Freedom Park Lagos
                </Typography>
                <Typography color="#667085" fontSize={12}>
                  {" "}
                  1, Old Prison Ground, Hospital Road, Lag..
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Search;
