import React, { createContext, useContext, useReducer, useEffect } from 'react';

// Initial state
const initialState = {
  rooms: [],
  messages: [],
  notifications: [],
  user: null,
  token: '',
  isLoading: false,
  authTrigger: false,
};

// Create context
const AppContext = createContext<any>(null);

// Reducer function
const appReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_ROOMS':
      return { ...state, rooms: action.payload };
    case 'SET_MESSAGES':
      return { ...state, messages: action.payload };
    case 'ADD_MESSAGE':
      return { ...state, messages: [...state.messages, action.payload] };
    case 'SET_NOTIFICATIONS':
      return { ...state, notifications: action.payload };
    case 'ADD_NOTIFICATION':
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case 'UPDATE_USER':
      return { ...state, user: action.payload };
    case 'SET_TOKEN':
      return { ...state, token: action.payload };
    case 'SET_AUTH_TRIGGER':
      return { ...state, authTrigger: action.payload };
    default:
      return state;
  }
};

// Provider component
export const AppProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  // Load initial state from localStorage
  useEffect(() => {
    const storedState = localStorage.getItem('appState');
    if (storedState) {
      dispatch({ type: 'LOAD_STATE', payload: JSON.parse(storedState) });
    }
  }, []);

  // Save state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('appState', JSON.stringify(state));
  }, [state]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook for using the context
export const useAppContext = () => {
  return useContext(AppContext);
};
