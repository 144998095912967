import { NavigateNext } from '@mui/icons-material';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import BottomDrawer from 'shared/BottomDrawer';

import CustomTimeline from 'shared/TravelLocationTimeline';

import { ReUseAbleTripCards } from 'shared/TripOrderContent';

import { useEffect, useState } from 'react';
import { AssignDriver } from './AssignDriver';
import NotStarted from './NotStarted';
import { calculateTimeDifference } from 'pages/Trips';

const SetTravellersDetail = ({
  bookingTrip,
  onClose,
}: {
  bookingTrip?: any;
  onClose?: any;
}) => {
  const [openStatus, setOpenStatus] = useState(false);
  const [openDriver, setOpenDriver] = useState(false);
  const [pendingDriver, setPendingDriver] = useState(false);
  const [pending, setPending] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const handleDriverSelect = (driver: string) => {
    setSelectedDriver(driver);
    setPendingDriver(true);
    setOpenDriver(false);
  };

  const handleStage = (currentStatus: string) => {
    setSelectedStatus(currentStatus);
    setPending(true);
    setOpenDriver(false);
  };

  return (
    <>
      <Box p={2}>
        <Typography fontWeight={600}>Set up travel details</Typography>
        <ReUseAbleTripCards
          title='Thu, May 23, 2024'
          subtitle={calculateTimeDifference(
            bookingTrip.starttime,
            bookingTrip.endtime
          )}
        >
          <CustomTimeline
            from={bookingTrip.from}
            to={bookingTrip.to}
            start_park={bookingTrip.parkbus_stop}
            start_time={bookingTrip.starttime}
            stop_park={bookingTrip.stop_park}
            stop_time={bookingTrip.endtime}
          />
        </ReUseAbleTripCards>

        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          border='1px solid #E4E7EC'
          borderRadius='8px'
          p='12px'
          sx={{ cursor: 'pointer' }}
          mt='8px'
          onClick={() => setOpenDriver(true)}
        >
          <Typography fontWeight={600} fontSize={12}>
            Assign Driver
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {pendingDriver ? (
              <></>
            ) : (
              <CircularProgress sx={{ color: '#ffc632' }} size={20} />
            )}
            <Typography
              sx={{
                fontSize: '12px',
                color: pendingDriver ? '#000' : '#FFC632',
                fontWeight: 600,
              }}
            >
              {pendingDriver ? `${selectedDriver}` : 'Pending'}
            </Typography>
            <NavigateNext />
          </div>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          border='1px solid #E4E7EC'
          borderRadius='8px'
          p='12px'
          sx={{ cursor: 'pointer' }}
          mt='8px'
          onClick={() => setOpenStatus(true)}
        >
          <Typography fontWeight={600} fontSize={12}>
            Update Status
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Typography
              sx={{
                fontSize: '12px',
                color: '#FFC632',
                textTransform: 'uppercase',
              }}
            >
              {pending ? `${selectedStatus}` : 'Not Started'}
            </Typography>
            <NavigateNext />
          </div>
        </Box>
        <Button
          onClick={onClose}
          sx={{
            display: pending === false ? 'none' : 'grid',
            my: 2,
            borderRadius: '100px',
          }}
          variant='contained'
          fullWidth
        >
          Continue
        </Button>
      </Box>
      <BottomDrawer open={openDriver} onClose={() => setOpenDriver(false)}>
        <AssignDriver
          onSelectDriver={handleDriverSelect}
          bookingTrip={bookingTrip}
        />
      </BottomDrawer>

      <BottomDrawer
        drawerHeight='45vh'
        open={openStatus}
        onClose={() => setOpenStatus(false)}
        removeTop={true}
      >
        <NotStarted
          onClose={() => {
            onClose();
            setOpenStatus(false);
          }}
          onSelectStage={handleStage}
          bookingTrip={bookingTrip}
        />
      </BottomDrawer>
    </>
  );
};

export default SetTravellersDetail;
