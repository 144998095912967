import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as const;

type IDay = (typeof daysOfWeek)[number];

interface SelectableDaysProps {
  setValue: any;
  error: string;
  setError: any;
}

const SelectableDays: React.FC<SelectableDaysProps> = ({
  setValue,
  error,
  setError,
}) => {
  const [selectedDays, setSelectedDays] = useState<IDay[]>([]);

  const toggleDay = (day: IDay) => {
    setSelectedDays((prevSelectedDays: IDay[]) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((d) => d !== day)
        : [...prevSelectedDays, day]
    );
  };

  useEffect(() => {
    setValue('selectedDays', selectedDays);
    if (selectedDays.length > 0) {
      setError('selectedDays', '');
    }
    //  resetField({ selectedDays: selectedDays });
    // Log when a day is selected or unselected

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDays, setValue]);

  return (
    <Box>
      <Typography fontSize={12} fontWeight={600} mb={1}>
        Select Reoccuring days
      </Typography>
      <Box
        display='flex'
        width='100%'
        alignItems='center'
        flexWrap='wrap'
        gap={2}
        my={2}
      >
        {daysOfWeek.map((day) => (
          <Box
            role='button'
            key={day}
            onClick={() => toggleDay(day)}
            sx={{
              borderRadius: '8px',
              border: '1px solid #A5D6A7',
              borderColor: selectedDays.includes(day) ? 'darkgreen' : '#A5D6A7',
              color: 'darkgreen',
              width: 'auto',
              height: '50px',
              textTransform: 'none',
              bgcolor: selectedDays.includes(day) ? '#E8F5E9' : 'transparent',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: 2,
            }}
          >
            {day}
          </Box>
        ))}
      </Box>
      {error && (
        <Typography
          sx={{
            color: '#d32f2f',
            fontSize: '0.75rem',
            marginTop: '3px',
            textAlign: 'left',
            mb: 2,
          }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default SelectableDays;
