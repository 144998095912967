import { ArrowBack, Check, Person } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AddNewDriver from 'shared/BottomDrawerContent/AddNewDriver';
import BottomDrawer from 'shared/BottomDrawer';
import { EditDriverContact } from 'shared/EditDriverContact';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { keyframes } from '@mui/material';
import driverIcon from 'assets/drivers.png';
import AddNewDV from './AddNewDV';
import SetTravellersDetail from './SetTravellersDetail';
import { useMutation } from '@tanstack/react-query';
import { assignDriver } from 'services/booking';
import useDriver from 'hooks/useDriver';

const comeIn = keyframes`
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
`;

export const AssignDriver = ({
  onSelectDriver,
  bookingTrip,
}: {
  onSelectDriver?: (driverName: string) => void;
  bookingTrip?: any;
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [edit, setEdit] = React.useState(true);

  const handleEdit = () => {
    setEdit(!edit);
  };

  const assignDriverMutation = useMutation({
    mutationFn: async (data: { trip_id: string; driver_id: string }) => {
      return assignDriver(data);
    },
    onSuccess: (data) => {
      setOpen(true);
    },
    onError: (err) => {},
  });

  const tripId = bookingTrip.trip_id;

  const onSubmitData = (
    tripId: string,
    driverId: string,
    driverName: string
  ) => {
    const data = {
      trip_id: tripId,
      driver_id: driverId,
    };

    assignDriverMutation.mutate(data);

    if (onSelectDriver) {
      onSelectDriver(driverName);
    }
  };

  const { data: drivers, isLoading } = useDriver();

  const driversList = drivers?.data?.result || [];

  if (isLoading) {
    return (
      <Box
        height='100vh'
        width='100%'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <BottomDrawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        {/* <AddNewDV /> */}
        <SetTravellersDetail />
      </BottomDrawer>
      <Box p={3}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            gap: 2,
          }}
          mb={1}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography fontWeight={800} fontSize={20}>
            Assign Driver
          </Typography>
        </Box>
        <Box
          border='1px solid #E4E7EC'
          bgcolor='#F6F6F6'
          borderRadius='8px'
          mt={3}
          overflow='hidden'
          height={94}
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='flex-end'
          gap={2}
          p={1}
          onClick={() => {
            setOpen(true);
          }}
        >
          <img src={driverIcon} alt='' height={32} width={32} />
          <Typography fontSize={12}>+ Create new</Typography>
        </Box>
        <Box
          border='1px solid #E4E7EC'
          borderRadius='8px'
          mt={3}
          overflow='hidden'
        >
          <Box
            bgcolor='#F6F6F6'
            p={1}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography fontSize='12px' fontWeight={600}>
              Saved
            </Typography>
            <Button
              variant='text'
              sx={{
                color: edit ? '#3E7EFF' : 'red',
                fontSize: '12px',
                fontWeight: 600,
              }}
              onClick={handleEdit}
            >
              {edit ? 'Edit' : 'Cancel'}
            </Button>
          </Box>
          <Box>
            {driversList?.length === 0 && !isLoading && (
              <Box
                display='flex'
                alignItems='center'
                p={3}
                justifyContent='center'
              >
                <Typography>No Drivers, Kindly Create</Typography>
              </Box>
            )}
            {driversList.map((driver: any) => (
              <Box
                key={driver._id}
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                p={1}
                sx={{ cursor: 'pointer' }}
                onClick={() => onSubmitData(tripId, driver._id, driver.name)}
              >
                <Box display='flex' alignItems='center' gap={edit ? 2 : 1}>
                  {edit ? (
                    ''
                  ) : (
                    <RemoveCircleIcon
                      sx={{ color: '#DA4733', height: 30, width: 30 }}
                      fontSize='medium'
                      onClick={() => {}} // this function will remove a driver from the list
                    />
                  )}
                  <Avatar sx={{ bgcolor: '#98A2B3', height: 40, width: 40 }}>
                    OE
                  </Avatar>

                  <Box>
                    <Typography fontSize={12} color='#667085'>
                      Driver
                    </Typography>
                    <Typography fontSize={12} fontWeight={600}>
                      {driver.name}
                    </Typography>
                  </Box>
                </Box>
                <Box textAlign='right'>
                  <Typography fontSize={12} color='#667085'>
                    Phone
                  </Typography>
                  <Typography fontSize={12} fontWeight={600}>
                    {driver.phone}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Button
          sx={{ mt: 6 }}
          variant='contained'
          fullWidth
          onClick={() => {
            setOpen(true);
          }}
        >
          Continue
        </Button>
      </Box>
    </>
  );
};
