import { Box, Typography } from "@mui/material";

import { ReactComponent as OrderArrow } from "assets/order-arrow.svg";
import { ReactComponent as BusIcon } from "assets/bus-icon.svg";

import { ReactComponent as ThunderIcon } from "assets/thunder-bolt.svg";
import { ReactComponent as CircularIcon } from "assets/circular-icon.svg";

const OrderCard = ({
  onClick,
  from,
  to,
  starttime,
  endtime,
  stoppark,
  startpark,
  chioce,
  seatsLeft,
  noOfSeats,
}: {
  onClick?: () => void;
  from?: string;
  to?: string;
  starttime?: string;
  endtime?: string;
  status?: string;
  startpark?: string;
  stoppark?: string;
  chioce?: string;
  seatsLeft?: string;
  noOfSeats?: string;
}) => {
  return (
    <Box
      onClick={onClick}
      my={1}
      mx={1}
      p={2}
      border="1px solid #E4E7EC"
      boxShadow="0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A"
      borderRadius="8px"
      sx={{ cursor: "pointer" }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          fontWeight={700}
          color="#379E66"
          sx={{ textTransform: "uppercase" }}
        >
          {starttime}
        </Typography>
        <Typography
          fontWeight={700}
          color="#379E66"
          sx={{ textTransform: "uppercase" }}
        >
          {endtime}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="12px" textAlign="left">
          {startpark}
        </Typography>
        <OrderArrow />
        <Typography fontSize="12px" textAlign="right">
          {stoppark}
        </Typography>
      </Box>
      <Box
        display="flex"
        mt={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography fontSize="12px" color="#98A2B3">
          {from}
        </Typography>
        <Typography fontSize="12px" color="#98A2B3">
          {to}
        </Typography>
      </Box>
      <Box mt={1} display="flex" gap={1} alignItems="center">
        <BusIcon />
        <Typography
          fontWeight={600}
          bgcolor="#F7F7F7"
          padding="2px 8px 2px 6px"
          fontSize="12px"
          color="#717171"
          borderRadius="16px"
        >
          {seatsLeft} seats left
        </Typography>
        <Typography
          fontWeight={600}
          bgcolor="#F7F7F7"
          padding="2px 8px 2px 6px"
          fontSize="12px"
          color="#717171"
          borderRadius="16px"
        >
          {noOfSeats} seater
        </Typography>
      </Box>
      <Box mt={1} display="flex" gap={1} alignItems="center">
        <Typography
          fontWeight={600}
          bgcolor="#FFFAEB"
          padding="2px 8px 2px 6px"
          fontSize="12px"
          color="#B54708"
          borderRadius="16px"
          display="flex"
          gap={1}
          alignItems="center"
        >
          <ThunderIcon /> <span>2km</span>
        </Typography>
        <Typography
          fontWeight={600}
          bgcolor="#EEF4FF"
          padding="2px 8px 2px 6px"
          fontSize="12px"
          color="#3538CD"
          borderRadius="16px"
          display="flex"
          gap={1}
          alignItems="center"
        >
          <CircularIcon /> <span> {chioce}</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default OrderCard;
