import { ArrowBack, WalletOutlined } from '@mui/icons-material';
import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import PendingIcon from '@mui/icons-material/Pending';
import AddIcon from '@mui/icons-material/Add';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

import { useState } from 'react';
import DashboardLayout from 'layouts/DashboardLayout';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getWalletBalance, getWalletHistory } from 'services/wallet';
import { formatCurrency, formatDate } from 'utils/misc';

export const Wallet = () => {
  // const [open, setOpen] = useState(false);
  // const [openTopUpWallet, setOpenTopUpWallet] = useState(false);

  const navigate = useNavigate();
  // const walletCard = [
  //   {
  //     icon: <AddIcon sx={{ color: '#379E66' }} />,
  //     title: 'Top Up Wallet',
  //     onClick: () => {
  //       setOpenTopUpWallet(true);
  //     },
  //   },
  //   {
  //     icon: <ArrowOutwardIcon sx={{ color: '#379E66' }} />,
  //     title: 'Withdraw',
  //   },
  //   {
  //     icon: <WalletOutlined sx={{ color: '#379E66' }} />,
  //     title: 'Virtual Card',
  //     onClick: () => {
  //       setOpen(true);
  //     },
  //   },
  // ];

  const walletHistory = useQuery({
    queryKey: ['WALLET_HISTORY'],
    queryFn: getWalletHistory,
  });

  const walletBalance = useQuery({
    queryKey: ['WALLET_BALANCE'],
    queryFn: getWalletBalance,
  });

  if (walletHistory?.isPending || walletBalance?.isPending) {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        width='100%'
        height='100vh'
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <DashboardLayout>
      <Box p={2} mx={{ md: '14%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            gap: 2,
          }}
          mb={1}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography fontWeight={800} fontSize={20}>
            Wallet
          </Typography>
        </Box>

        <Box
          display={{ xs: 'block', md: 'grid' }}
          sx={{ gridTemplateColumns: '100%', gap: '12px' }}
        >
          <Box>
            {/* <Card sx={{ height: '146px', borderRadius: 2 }} elevation={0}>
              <Box bgcolor='#025F2D' p={2}>
                <Typography color='white' fontSize={12} mb={1}>
                  Drivers Balance
                </Typography>
                <Typography color='white' fontSize={12}>
                  ₦ 1,005,490.00
                </Typography>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                bgcolor='#379E66'
                py={1}
                px={2}
              >
                <Typography color='white' fontSize={12} mb={-1}>
                  Your Balance
                </Typography>
                <Typography color='white' fontSize={25} fontWeight={700}>
                  ₦ 90,090.00
                </Typography>
              </Box>
            </Card> */}
            {/* <Box display='grid' gap={1} gridTemplateColumns='1fr 1fr 1fr'>
              {walletCard.map(({ title, icon, onClick }) => (
                <Box
                  width='100%'
                  display='flex'
                  alignItems='center'
                  flexDirection='column'
                  justifyContent='center'
                  border='1px solid #E4E7EC'
                  py={2}
                  borderRadius='8px'
                  my={1}
                  onClick={onClick}
                  sx={{ cursor: 'pointer' }}
                >
                  <>{icon}</>
                  <Typography
                    textAlign='center'
                    fontSize='10px'
                    mt={2}
                    color='#344054'
                  >
                    {title}
                  </Typography>
                </Box>
              ))}
            </Box> */}
          </Box>
          <Box
            border='1px solid #E4E7EC'
            mt={3}
            borderRadius='8px'
            overflow='hidden'
            width='100%'
          >
            <Box
              bgcolor='#F6F6F6'
              p={1}
              display='flex'
              alignItems='center'
              justifyContent='space-between'
            >
              <Typography fontSize='12px' fontWeight={600}>
                Transaction activities
              </Typography>
            </Box>
            <Box p={1} width='100%'>
              {walletHistory?.data?.data?.transactions?.length === 0 && (
                <Box
                  display='flex'
                  py={10}
                  alignItems='center'
                  justifyContent='center'
                >
                  <Typography>No recent items</Typography>
                </Box>
              )}
              {walletHistory?.data?.data?.transactions?.map(
                ({ note, amount, createdAt }: any) => (
                  <Box
                    p={1}
                    border='1px solid #E4E7EC'
                    borderRadius='8px'
                    overflow='hidden'
                    my={1}
                  >
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                      mb={1}
                    >
                      <Box display='flex' alignItems='center' gap={1}>
                        {/* <div
                        style={{
                          backgroundColor: '#DA4733',
                          borderRadius: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: 24,
                          width: 24,
                        }}
                      >
                        <ArrowOutwardIcon
                          sx={{ color: 'white', height: 18, width: 18 }}
                        />
                      </div> */}
                        <Typography width='65%' fontSize={12} fontWeight={600}>
                          {note}
                        </Typography>
                      </Box>
                      <Typography fontSize={12} fontWeight={600}>
                        {formatCurrency(amount)}
                      </Typography>
                    </Box>
                    <Typography color='#98A2B3' fontSize={12}>
                      {formatDate(createdAt)}
                    </Typography>
                  </Box>
                )
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};
