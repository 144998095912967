import { CheckCircleOutline, ErrorOutlineOutlined } from "@mui/icons-material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Chip, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ProfileLayout } from "layouts/ProfileLayout";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BottomDrawer from "shared/BottomDrawer";
import { CompleteProfileVerification } from "shared/BottomDrawerContent/CompleteProfileVerification";
import LevelUpAccount from "shared/BottomDrawerContent/LevelUpAccount";

const Verification = () => {
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const navigate = useNavigate();
  return (
    <ProfileLayout>
      <BottomDrawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <CompleteProfileVerification />
      </BottomDrawer>
      <BottomDrawer
        open={opened}
        onClose={() => {
          setOpened(false);
        }}
      >
        <LevelUpAccount />
      </BottomDrawer>
      <Box height="100vh">
        <Box py={2} px={3}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography fontWeight={700} fontSize={20}>
                Verification
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={3}
              py={1}
              onClick={() => {
                setOpened(true);
              }}
              sx={{ cursor: "pointer" }}
            >
              <div>
                <Chip
                  size="small"
                  sx={{ bgcolor: "green", color: "white", fontSize: 10 }}
                  label="Completed"
                />
                <Typography fontSize="14px" fontWeight={600}>
                  Level 1
                </Typography>
                <Typography my="4px" color="#667085" fontSize="12px">
                  Email and Phone Number Verification
                </Typography>
              </div>
              <CheckCircleOutline color="primary" />
            </Box>
            <Divider />
          </Box>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt="8px"
              py={1}
              onClick={() => {
                setOpen(true);
              }}
              sx={{ cursor: "pointer" }}
            >
              <div>
                <Typography fontSize="14px" fontWeight={600}>
                  Level 2
                </Typography>
                <Typography my="4px" color="#667085" fontSize="12px">
                  National identity Number
                </Typography>
              </div>
              <ErrorOutlineOutlined color="error" />
            </Box>
            <Divider />
          </Box>
        </Box>
      </Box>
    </ProfileLayout>
  );
};

export default Verification;
