import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

const Move9jaCard = ({
  title,
  children,
  marginTop = 3,
  endText,
}: {
  title: string;
  children: ReactNode;
  marginTop?: number;
  endText?: ReactNode | string;
}) => {
  return (
    <Box
      border='1px solid #E4E7EC'
      borderRadius='8px'
      mt={marginTop}
      overflow='hidden'
    >
      <Box
        bgcolor='#F6F6F6'
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        p={1}
      >
        <Typography fontSize='12px' fontWeight={600}>
          {title}
        </Typography>
        <Typography fontSize='12px' fontWeight={600}>
          {endText}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};
export default Move9jaCard;
