import {
  Box,
  Typography,
  Drawer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactNode } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { keyframes } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

interface BottomDrawerProps {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  drawerHeight?: string;
  endText?: string | ReactNode;
  removeTop?: boolean;
}

const BottomDrawer = ({
  children,
  open,
  onClose,
  drawerHeight = "90vh",
  endText,
  removeTop = false,
}: BottomDrawerProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

  if (!open) return null;

  const DrawerContent = (
    <>
      <Box
        display={removeTop ? "none" : "flex"}
        alignItems="center"
        justifyContent="space-between"
        borderBottom="0.5px solid #DADADA"
        p={1}
        sx={{
          animation: `${fadeIn} 0.5s ease-out 0.3s forwards`,
          opacity: 0, // Start with 0 opacity for the fade-in effect
        }}
      >
        <Typography
          display="flex"
          alignItems="center"
          fontWeight={600}
          fontSize="14px"
          onClick={() => {
            navigate(-1);
          }} // Handle go back action
          sx={{ cursor: "pointer" }}
        >
          <ChevronLeftIcon />
          <span>Go back</span>
        </Typography>
        {endText || (
          <Typography
            fontSize="14px"
            color="#DA4733"
            onClick={onClose} // Handle cancel action
            sx={{ cursor: "pointer" }}
          >
            Cancel
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          overflowY: "scroll", // Use scroll to enable scrolling
          maxHeight: "calc(90vh - 40px)",
          pb: "40px",
          animation: `${fadeIn} 0.5s ease-out 0.8s forwards`,
          opacity: 0,
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar for webkit browsers
          },
          "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
          "scrollbar-width": "none", // Hide scrollbar for Firefox
        }}
      >
        {children}
      </Box>
    </>
  );

  return isBigScreen ? (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "30vw",
          borderRadius: "12px 0px 0px 12px",
          animation: `${fadeIn} 0.5s ease-out`,
        },
      }}
    >
      {DrawerContent}
    </Drawer>
  ) : (
    <>
      {/* Backdrop */}
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100vw"
        height="100vh"
        bgcolor="rgba(0, 0, 0, 0.5)"
        zIndex={9}
        onClick={onClose} // Close the drawer when backdrop is clicked
      />

      {/* Bottom Drawer */}
      <Box
        height={drawerHeight}
        position="fixed"
        width="100vw"
        bottom={0}
        left={0}
        bgcolor="#fff"
        zIndex={10}
        borderRadius="12px 12px 0px 0px"
        sx={{
          animation: `${slideUp} 0.5s ease-out`,
        }}
      >
        {DrawerContent}
      </Box>
    </>
  );
};

export default BottomDrawer;
