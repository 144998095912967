import http from "utils/http";

export const createDriver = async (body: any) => {
  const res = http.post("/pm/create/createdriver", body);
  return (await res).data;
};

export const getDriver = async () => {
  const res = http.get("/pm/create/driverslist");
  return (await res).data;
};
