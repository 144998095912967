import { ArrowBack, ErrorOutline } from "@mui/icons-material";
import BottomDrawer from "shared/BottomDrawer";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useState } from "react";
import { keyframes } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AssignedLoginContent from "shared/BottomDrawerContent/AssignedLoginContent";
import UnAssignedLoginContent from "shared/BottomDrawerContent/UnAssignedLoginContent";
import InstallButton from "shared/InstallButton";
import { useMutation } from "@tanstack/react-query";
import { login } from "services/auth";
import { handleMutationError } from "utils/handler";
import backGroundImage from "../../assets/driverPic.jpeg";
import move9jaLogo from "../../assets/move9ja.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const errorComes = keyframes`
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
`;

const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
});

export const Login = () => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [notAssignedParkId, setNotAssignedParkId] = useState(false);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const mutation = useMutation({
    mutationFn: async (data: any) => {
      return await login({
        email: data?.email?.toLowerCase(),
        password: data?.password,
      });
    },
    onSuccess: (data) => {
      setIsLoading(false);
      navigate("/otp", { state: { userId: data?.data?.user_id } });
    },
    onError: (err) => {
      handleMutationError(err);
      setIsLoading(false);
    },
  });

  const onSubmit = (data: any) => {
    setIsLoading(true);
    mutation.mutate(data);
  };

  return (
    <Box height="100vh">
      <Box
        height="72px"
        p="0px 80px"
        display={{ xs: "none", md: "flex" }}
        alignItems="center"
        justifyContent="space-between"
        border="1px solid #E4E7EC"
      >
        <img src={move9jaLogo} alt="" width="162.75px" height="50.26px" />
        <Button
          sx={{
            bgcolor: "#DA4733",
            width: "109px",
            borderRadius: "8px",
            fontWeight: 600,
            fontSize: "14px",
          }}
          variant="contained"
        >
          Register
        </Button>
      </Box>
      <Box
        sx={{
          display: { xs: "block", md: "grid" },
          gridTemplateColumns: "40% 60%",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${backGroundImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left center",
            height: "952px",
            width: "100%",
            margin: 0,
            padding: 0,
            display: { xs: "none", md: "grid" },
            placeItems: "end",
          }}
        >
          <Typography
            color="#fff"
            fontSize="32px"
            fontWeight={600}
            height="92px"
            width="324px"
            mb={15}
            mr={20}
          >
            Request a bus ride, hop in, and go.
          </Typography>
        </Box>
        <Box
          display="flex"
          height={{ md: "100vh" }}
          alignItems="center"
          flexDirection="column"
        >
          <Box
            width={{ xs: "100%", md: "510px" }}
            p="0px 24px"
            mt={{ md: "70px", xs: "40px" }}
          >
            <>
              <Box>
                {/* {this drawer is so when the park id is confirmed} to open the drawing for now, you have to click on the blue card - Get started*/}
                <BottomDrawer
                  open={open}
                  onClose={() => {
                    setOpen(false);
                  }}
                >
                  <AssignedLoginContent />
                </BottomDrawer>
                {/* {this drawer is so when the park id is yet assigned} */}
                <BottomDrawer
                  open={open}
                  onClose={() => {
                    setOpen(false);
                  }}
                >
                  <UnAssignedLoginContent />
                </BottomDrawer>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: 2,
                  }}
                  mb={1}
                >
                  <IconButton
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <ArrowBack />
                  </IconButton>
                  <Typography fontWeight={800} fontSize={20}>
                    Login
                  </Typography>
                </Box>
                {/* <InstallButton /> */}
                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Typography mb={4} fontWeight={500}>
                    Enter your Phone Number
                  </Typography>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                      Email address
                    </InputLabel>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Enter your email address"
                          variant="standard"
                          InputProps={{ disableUnderline: true }}
                          sx={{
                            bgcolor: "#F9F9F9",
                            mt: 2,
                            mb: 2,
                            py: 1,
                            px: 2,
                            borderRadius: "10px",
                          }}
                          error={!!errors.email}
                          helperText={errors.email ? errors.email.message : ""}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                      Password
                    </InputLabel>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter your password"
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label={
                                    showPassword
                                      ? "Hide password"
                                      : "Show password"
                                  }
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            bgcolor: "#F9F9F9",
                            mt: 2,
                            borderRadius: "10px",
                            borderStyle: "none",
                            py: 1,
                            px: 2,
                          }}
                          error={!!errors.password}
                          helperText={
                            errors.password ? errors.password.message : ""
                          }
                        />
                      )}
                    />
                  </FormControl>
                  <Typography
                    sx={{ textDecoration: "underline" }}
                    fontSize={12}
                    fontWeight={500}
                    my={1}
                  >
                    Having issues with login?
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ fontWeight: 400, p: 1, borderRadius: 2, my: 2 }}
                    type="submit"
                  >
                    Login
                  </Button>
                </form>

                <Box mt={18}>
                  <Typography fontSize={12} textAlign="center" mx={4} mb={3}>
                    Creating an account means you have agreed to our{" "}
                    <span style={{ textDecoration: "underline" }}>Terms</span>{" "}
                    and
                    <span style={{ textDecoration: "underline" }}>
                      Privacy policy
                    </span>
                  </Typography>
                  <Card
                    onClick={() => setOpen(true)}
                    sx={{
                      display: "flex",
                      gap: 2,
                      p: 1,
                      borderRadius: 3,
                      backgroundColor: "#255398",
                    }}
                    variant="elevation"
                  >
                    <AccountCircleIcon sx={{ color: "white" }} />
                    <Box>
                      <Typography color="white" fontSize={14}>
                        Become a park agent in any of our registered parks in
                        Nigeria
                      </Typography>
                      <Typography color="white" fontWeight={200} fontSize={12}>
                        Get Started
                      </Typography>
                    </Box>
                    <CloseIcon sx={{ color: "white" }} />
                  </Card>
                </Box>
              </Box>
              {/* This box is to display an error when ever the login authentication fails, it comes from top */}
              {error && (
                <Card
                  variant="elevation"
                  sx={{
                    backgroundColor: "#DA4733",
                    color: "white",
                    width: "100%",
                    animation: `${errorComes} 2s ease-in-out`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    position: "absolute",
                    top: 0,
                    borderRadius: 0,
                    p: 1,
                  }}
                >
                  <ErrorOutline sx={{ color: "white" }} />
                  <Typography fontSize={14}>
                    We do not recognize this email
                  </Typography>
                </Card>
              )}
              {isLoading && (
                <>
                  <Box
                    position="absolute"
                    top={0}
                    sx={{
                      height: "100vh",
                      width: "100%",
                      backgroundColor: "transparent",
                      backgroundPosition: "no-repeat",
                      backgroundSize: "cover",
                      backdropFilter: "blur(2px)",
                    }}
                  />
                  <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    gap={1}
                    sx={{ zIndex: 2, transform: "translate(-50%, -50%)" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <CircularProgress />
                    <Typography fontSize={12} fontWeight={600}>
                      Confirming your details
                    </Typography>
                    <Typography fontSize={12}>Loading...</Typography>
                  </Box>
                </>
              )}
            </>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
