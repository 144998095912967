import { ArrowBack, CheckCircleOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";

export const FeedbackRecieved = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: "85vh",
        width: "100wh",
        backgroundColor: "white",
        display: "grid",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
      }}
      p={2}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap={2}
      >
        <CheckCircleOutline
          fontSize="small"
          color="primary"
          sx={{ height: 64, width: 64 }}
        />
        <Typography fontWeight={600} fontSize={20}>
          Feedback recieved
        </Typography>
        <Typography px={4} textAlign="center" fontWeight={500} fontSize={14}>
          Thank you for letting us know. We have notified our customers
          concerning their traveling request and suspended booking till it is
          resolved. Please ensure you update your availability in the app to
          allow next traveling orders.
        </Typography>
      </Box>
      <Button
        sx={{ color: "#fff", p: "12px 24px 12px 24px", borderRadius: "8px" }}
        variant="contained"
        onClick={() => {
          navigate("/dashboard");
        }}
      >
        Okay, Go Home
      </Button>
    </Box>
  );
};
