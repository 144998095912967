import http from "utils/http";

export const getBookings = async () => {
  const res = http.get("/pm/booking/getbookings");
  return (await res).data;
};

export const assignDriver = async (body: any) => {
  const res = http.post("/pm/booking/assigndriver", body);
  return (await res).data;
};

export const updateTripStatus = async (body: any) => {
  const res = http.post("/pm/booking/updatetripstatus", body);
  return (await res).data;
};
