import { ArrowUpward } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { ReactNode } from 'react';
import Move9jaCard from './Move9jaCard';
import CustomTimeline from './TravelLocationTimeline';
import TravelerDetails from './TravelerDetails';
import { Button } from '@mui/material';
// import Car from '../assets/car.png';
import BottomDrawer from './BottomDrawer';
// import TripConcluded from 'shared/TripConcluded';
import ReasonsForDecline from './BottomDrawerContent/ReasonsForDecline';
import { calculateTimeDifference } from 'pages/Trips';
import SetTravellersDetail from './BottomDrawerContent/SetTravellersDetail';

const TripOrderContent2 = ({ bookingTrip, onClose }: any) => {
  const [open, setOpen] = React.useState(false);
  const [openDecline, setOpenDecline] = React.useState(false);

  console.log('Booking trip', bookingTrip);
  const tripData = bookingTrip;

  const { status } = bookingTrip;

  return (
    <>
      <Box px={2}>
        <Typography mt={2} fontSize='24px' fontWeight={700}>
          Order: {bookingTrip.order_id}
        </Typography>
        <Typography my={1} fontSize='12px' color='#98A2B3' fontWeight={400}>
          Travel ID; {bookingTrip.trip_id}
        </Typography>
        <Typography
          width='84px'
          fontWeight={600}
          px={2}
          sx={{
            bgcolor: '#F0F9FF',
            color: '#026AA2',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '16px',
          }}
        >
          <ArrowUpward sx={{ color: '#0BA5EC', height: 12, width: 12 }} />
          <span style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>
            En Route
          </span>
        </Typography>

        <ReUseAbleTripCards
          title='Ongoing'
          bgcolor='#379E66'
          color='#fff'
          subtitle={calculateTimeDifference(
            bookingTrip.starttime,
            bookingTrip.endtime
          )}
        >
          <CustomTimeline
            from={bookingTrip.from}
            to={bookingTrip.to}
            start_park={bookingTrip.parkbus_stop}
            start_time={bookingTrip.starttime}
            stop_park={bookingTrip.stop_park}
            stop_time={bookingTrip.endtime}
          />
        </ReUseAbleTripCards>
        {status === 'Completed' && tripData?.driver?.name && (
          <ReUseAbleTripCards title='Bus Details'>
            <Box display='grid' gridTemplateColumns='1fr 1fr' gap={1} p={1}>
              {/* <img src={Car} alt='' height={74} width={74} /> */}
              <Box fontSize={12}>
                <Typography fontSize={12}>Vehicle</Typography>
                <Typography fontSize={12} fontWeight={600}>
                  {tripData.car_model}
                </Typography>
                <Typography mt={2} fontSize={12}>
                  Driver
                </Typography>
                <Typography
                  sx={{
                    textTransform: 'capitalize',
                  }}
                  fontWeight={600}
                  fontSize={12}
                >
                  Mr {tripData?.driver?.name}
                </Typography>
              </Box>
              <Box display='flex' alignItems='flex-end' flexDirection='column'>
                <Typography fontSize={12}>License Plate</Typography>
                <Typography fontSize={12} fontWeight={600}>
                  {' '}
                  {tripData.license_plate}
                </Typography>
                <Typography mt={3} fontSize={12}>
                  Phone
                </Typography>
                <Typography fontSize={12} fontWeight={600}>
                  {tripData?.driver?.phone}
                </Typography>
              </Box>
            </Box>
          </ReUseAbleTripCards>
        )}
        <Move9jaCard title="Traveller's Details">
          <Box m={1}>
            {bookingTrip.passengers.map((pass: any) => (
              <TravelerDetails
                amount={pass.pass.amount}
                name={pass.pass.fullname}
                payMtd={pass.pass.paymentType}
                phone={pass.pass.phoneNumber}
                seatNumber={pass.pass.selected_seat}
              />
            ))}
          </Box>
        </Move9jaCard>

        {status !== 'Completed' && (
          <Box mt={1}>
            <Button
              fullWidth
              sx={{ borderRadius: '100px' }}
              variant='contained'
              onClick={() => {
                setOpen(true);
              }}
            >
              Accept
            </Button>
            <Box mt={1}>
              <Button
                fullWidth
                sx={{
                  borderRadius: '100px',
                  bgcolor: '#F2F4F7',
                  color: '#475467',
                }}
                variant='contained'
                onClick={() => setOpenDecline(true)}
              >
                Decline
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <BottomDrawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <SetTravellersDetail
          onClose={() => {
            onClose();
            setOpen(false);
          }}
          bookingTrip={bookingTrip}
        />
      </BottomDrawer>
      <BottomDrawer
        open={openDecline}
        onClose={() => {
          setOpenDecline(false);
        }}
      >
        <ReasonsForDecline />
      </BottomDrawer>
    </>
  );
};
export default TripOrderContent2;

export const ReUseAbleTripCards = ({
  title,
  subtitle,
  bgcolor = '#F6F6F6',
  children,
  color = '#000',
  onClick,
}: {
  title: string;
  children: ReactNode;
  subtitle?: string;
  bgcolor?: string;
  color?: string;
  onClick?: () => void;
}) => {
  return (
    <Box
      border='1px solid #E4E7EC'
      borderRadius='8px'
      mt={3}
      overflow='hidden'
      onClick={onClick}
      sx={{ cursor: 'pointer' }}
    >
      <Box
        bgcolor={bgcolor}
        color={color}
        p={1}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography fontSize='12px' fontWeight={400}>
          {title}
        </Typography>
        <Typography fontSize='12px' fontWeight={400}>
          {subtitle}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};
