import ArrowBack from '@mui/icons-material/ArrowBack';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import React, { useLayoutEffect } from 'react';
import { keyframes } from '@emotion/react';
import BottomDrawer from 'shared/BottomDrawer';
import ReferralLinkContent from 'shared/BottomDrawerContent/ReferalLinkContent';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ProfileLayout } from 'layouts/ProfileLayout';
import { useQuery } from '@tanstack/react-query';
import { getParkDetails } from 'services/auth';
import ConfirmDialog from 'shared/DeleteDialog';

const comeIn = keyframes`
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
`;
const ProfileDetails = () => {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [userName, setUserName] = React.useState('');
  const [dob, setDob] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [saved, setSaved] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  const [searchParams] = useSearchParams();

  const openParam = searchParams.get('open');

  useLayoutEffect(() => {
    console.log('Open param', open);
    if (openParam) {
      setOpen(true);
    }
  }, []);

  const { data, isLoading } = useQuery({
    queryKey: ['PARK_DETAILS'],
    queryFn: getParkDetails,
  });

  const userDetails = data?.data?.detials?.user_details;

  const navigate = useNavigate();

  if (isLoading) {
    return (
      <Box
        height='100vh'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <ProfileLayout>
      <Box p={2} position='relative'>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            gap: 2,
          }}
          mb={1}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography fontWeight={700} fontSize={20}>
            Profile details
          </Typography>
        </Box>
        <form autoComplete='off' onSubmit={handleSubmit}>
          <Typography mb={4} fontWeight={500}>
            Complete profile details correctly
          </Typography>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            gap={2}
          >
            <FormControl variant='standard' fullWidth>
              <InputLabel shrink sx={{ fontWeight: 800 }}>
                First Name
              </InputLabel>
              <TextField
                placeholder='John'
                variant='standard'
                InputProps={{ disableUnderline: true }}
                sx={{
                  bgcolor: '#F9F9F9',
                  mt: 2.5,
                  py: 1,
                  px: 2,
                  borderRadius: '10px',
                }}
                type='text'
                disabled
                value={userDetails?.firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormControl>
            <FormControl variant='standard' fullWidth>
              <InputLabel shrink sx={{ fontWeight: 800 }}>
                Last Name
              </InputLabel>
              <TextField
                placeholder='Doe'
                type='text'
                variant='standard'
                InputProps={{ disableUnderline: true }}
                sx={{
                  bgcolor: '#F9F9F9',
                  borderRadius: '10px',
                  borderStyle: 'none',
                  mt: 2.5,
                  py: 1,
                  px: 2,
                }}
                disabled
                value={userDetails?.lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </FormControl>
          </Box>
          <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink sx={{ fontWeight: 800 }}>
              Username
            </InputLabel>
            <TextField
              placeholder='jonny'
              variant='standard'
              InputProps={{ disableUnderline: true }}
              disabled
              sx={{
                bgcolor: '#F9F9F9',
                mt: 2.5,
                mb: 2,
                py: 1,
                px: 2,
                borderRadius: '10px',
              }}
              type='text'
              value={userDetails?.username}
              onChange={(e) => setUserName(e.target.value)}
            />
          </FormControl>
          <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink sx={{ fontWeight: 800 }}>
              Phone Number
            </InputLabel>
            <TextField
              placeholder='+234'
              variant='standard'
              disabled
              InputProps={{ disableUnderline: true }}
              sx={{
                bgcolor: '#F9F9F9',
                mt: 2.5,
                mb: 2,
                py: 1,
                px: 2,
                borderRadius: '10px',
              }}
              type='number'
              value={userDetails?.phoneNumber}
              onChange={(e) => setPhone(e.target.value)}
            />
          </FormControl>
          <FormControl variant='standard' fullWidth>
            <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
              Email Address
            </InputLabel>
            <TextField
              placeholder='Enter a valid email'
              type='email'
              disabled
              variant='standard'
              InputProps={{ disableUnderline: true }}
              sx={{
                bgcolor: '#F9F9F9',
                mt: 2,
                borderRadius: '10px',
                borderStyle: 'none',
                py: 1,
                px: 2,
              }}
              value={userDetails?.email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>

          {/* <Button
            variant='contained'
            color='primary'
            fullWidth
            sx={{ fontWeight: 400, p: 1, borderRadius: 2, my: 2 }}
            type='submit'
            onClick={() => setSaved(true)}
          >
            Continue
          </Button> */}
          {/* <Typography
            // variant='contained'
            // fullWidth
            component='button'
            sx={{
              fontWeight: 400,
              p: 1,
              borderRadius: 2,
              bgcolor: '#FFF5F4',
              color: 'red',
              mt: 3,
              // width: "content-fit",
            }}
            type='submit'
            onClick={handleOpenDialog}
          >
            Delete account
          </Typography> */}
        </form>
        {/* This box is to display the saved successfully  when ever the user completes his or her profile */}
      </Box>

      <BottomDrawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <ReferralLinkContent />
      </BottomDrawer>

      {/* <ConfirmDialog openDialog={true} handleCloseDialog={() => {}} /> */}
    </ProfileLayout>
  );
};

export default ProfileDetails;
