import { useQuery } from "@tanstack/react-query";
import { getTripHistory } from "services/trips";

const useTrips = () => {
  const query = useQuery({
    queryKey: ["GET_TRIPS"],
    queryFn: getTripHistory,
  });

  return query;
};
export default useTrips;
