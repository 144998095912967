import { Box, Typography } from "@mui/material";
import { ReactNode, useState } from "react";
import SimpleBottomNavigation from "shared/BottomNavigation";
import NavBar from "./NavBar";
import { Close } from "@mui/icons-material";

const DashboardLayout = ({ children }: { children: ReactNode }) => {
  const [openSearch, setOpenSearch] = useState(false);
  return (
    <>
      <Box
        display="grid"
        flexDirection={{ xs: "column", md: "row" }}
        minHeight="100vh"
        overflow="hidden"
      >
        <Box
          display={{ xs: "none", md: "block" }}
          width={{ xs: "0%", md: "100%" }}
          p={2}
        >
          <NavBar openSearch={openSearch} setOpenSearch={setOpenSearch} />
        </Box>
        <Box flex={1} minHeight="100vh" sx={{ overflowY: "auto", pb: 13 }}>
          {children}
        </Box>
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100vw",
          zIndex: 1,
          display: { md: "none", xs: "block" },
        }}
      >
        <SimpleBottomNavigation />
      </Box>
      <Box
        display={openSearch ? "block" : "none"}
        position="absolute"
        top="12%"
        right="18%"
        bgcolor="#FFF"
        p={2}
        borderRadius="10px"
        width="394px"
        border="1px solid #ECE7E4"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography my={2}>History</Typography>
          <Close
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpenSearch(false);
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: "12px",
            borderRadius: "8px",
            border: "1px solid #E4E7EC",
          }}
          mb={1}
        >
          <Typography fontSize={12} fontWeight={600}>
            {" "}
            LAGOS
          </Typography>
          <Close fontSize="small" />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: "12px",
            borderRadius: "8px",
            border: "1px solid #E4E7EC",
          }}
          mb={1}
        >
          <Typography fontSize={12} fontWeight={600}>
            {" "}
            ABUJA
          </Typography>
          <Close fontSize="small" />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: "12px",
            borderRadius: "8px",
            border: "1px solid #E4E7EC",
          }}
        >
          <Typography fontSize={12} fontWeight={600}>
            {" "}
            PORTHARCOURT
          </Typography>
          <Close fontSize="small" />
        </Box>
      </Box>
    </>
  );
};

export default DashboardLayout;
