import { useEffect } from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useNavigate, useLocation } from 'react-router-dom';

import { ReactComponent as ScheduleIcon } from 'assets/schedule-icon.svg';
import { ReactComponent as TripIcon } from 'assets/trips-icon.svg';
import { ReactComponent as WalletIcon } from 'assets/wallets-icon.svg';
import { ReactComponent as ActivityIcon } from 'assets/activities-icon.svg';
import { Avatar, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getParkDetails } from 'services/auth';
import useSocket from 'hooks/useSocket';
import { useAppContext } from 'context/chatProvider';

export default function SimpleBottomNavigation() {
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading } = useQuery({
    queryKey: ['PARK_DETAILS'],
    queryFn: getParkDetails,
  });

  const { socket } = useSocket();

  const { state, dispatch } = useAppContext();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      socket?.on('userRooms', (e) => {
        dispatch({ type: 'SET_ROOMS', payload: e });
      });

      // socket?.on('roomMessages', (e) => {
      //   dispatch({ type: 'SET_MESSAGES', payload: e.messages });
      // });

      socket?.on('receiveMessage', (e) => {
        if (e.newMessage.sender[0] !== state.user?._id) {
          // Show toast for new message
        }
        dispatch({ type: 'ADD_MESSAGE', payload: e.newMessage });
      });

      socket?.on('allNotification', (e) => {
        dispatch({ type: 'SET_NOTIFICATIONS', payload: e });
      });

      socket?.on('notification', (e) => {
        dispatch({ type: 'ADD_NOTIFICATION', payload: e });
      });
    }

    return () => {
      socket?.off('userRooms');
      socket?.off('roomMessages');
      socket?.off('receiveMessage');
      socket?.off('allNotification');
      socket?.off('notification');
    };
  }, [socket, state.token, dispatch, state.user]);

  if (isLoading) {
    return (
      <Box
        display='flex'
        bgcolor='#fff'
        alignItems='center'
        justifyContent='center'
        width='100%'
        height='100%'
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
      }}
    >
      <BottomNavigation
        showLabels
        value={location.pathname} // This will bind the current path to the BottomNavigation
        sx={{
          '&.MuiBottomNavigation-root': {
            height: '88px',
            boxShadow:
              '0px 2px 4px -2px #1018280F, 0px -4px 8px -2px #1018281A',
          },
          '.MuiBottomNavigationAction-label': {
            mt: 1,
            color: '#98A2B3',
            fontSize: '0.6rem',
          },
          '.Mui-selected': {
            fontSize: '0.65rem !important',
            fontWeight: 700,
            color: 'primary.main', // Ensures text color changes to primary
          },
          '.Mui-selected .MuiBottomNavigationAction-iconOnly': {
            color: 'primary.main', // Ensures icon color changes to primary
          },
        }}
      >
        <BottomNavigationAction
          value='/dashboard'
          onClick={() => navigate('/dashboard')}
          label='Schedule'
          icon={<ScheduleIcon />}
        />
        <BottomNavigationAction
          value='/trips'
          onClick={() => navigate('/trips')}
          label='Trips'
          icon={<TripIcon />}
        />
        <BottomNavigationAction
          value='/wallet'
          onClick={() => navigate('/wallet')}
          label='Transactions'
          icon={<WalletIcon />}
        />
        <BottomNavigationAction
          value='/activities'
          onClick={() => navigate('/activities')}
          label='Activities'
          icon={<ActivityIcon />}
        />
        <BottomNavigationAction
          value='/profile'
          onClick={() => navigate('/profile')}
          label='Accounts'
          icon={
            <Avatar sx={{ width: '24px', height: '24px', p: 2 }}>
              {data?.data?.detials.user_details?.firstName[0] || 'M'}
            </Avatar>
          }
        />
      </BottomNavigation>
    </Box>
  );
}
