import { Box, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import DashboardLayout from "layouts/DashboardLayout";

import Move9jaCard from "shared/Move9jaCard";
import Move9jaTravelTimeline from "shared/Move9jaTravelTimeline";
import CustomTimeline from "shared/TravelLocationTimeline";
import TravelerDetails from "shared/TravelerDetails";

import ArrowBack from "@mui/icons-material/ArrowBack";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import { useNavigate } from "react-router-dom";

const TravelDetails = () => {
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <Box p={2}>
        <Box display='flex' alignItems='center' mb={3}>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
            sx={{ mr: 1 }}
          >
            <ArrowBack />
          </IconButton>
          <Typography fontSize='20px' fontWeight={700}>
            Travel details
          </Typography>
        </Box>
        <Typography color='#000000' fontSize='24px' fontWeight={700}>
          Order: #78948BC-98
        </Typography>
        <Typography color='#98A2B3' fontSize='12px'>
          Travel ID; 476799200282
        </Typography>

        <Box display='flex' alignItems='center' mt={2}>
          <Chip
            sx={{ bgcolor: "#F0F9FF", color: "#026AA2" }}
            label={
              <Box display='flex' alignItems='center'>
                <Tooltip
                  open
                  arrow
                  title='Let us know when the bus has began the journey by updated the status of this travel ID'
                >
                  <ArrowUpwardIcon />
                </Tooltip>
                <Typography sx={{ ml: 0.5 }} fontWeight={600}>
                  En Route
                </Typography>
              </Box>
            }
          />
          <IconButton sx={{ ml: 1 }}>
            <UnfoldMoreIcon sx={{ color: "#667085" }} />
          </IconButton>
        </Box>

        <Move9jaCard title='Thu, May 23, 2024'>
          <CustomTimeline />
        </Move9jaCard>

        <Move9jaCard title="Traveller's Details">
          <Box p={2}>
            {[1, 2, 3].map((_) => (
              <TravelerDetails />
            ))}
          </Box>
          <Box
            px={2}
            pb={1}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Typography
              mx='auto'
              fontSize='12px'
              fontWeight={700}
              color='#0085FF'
              component='button'
            >
              View more
            </Typography>
          </Box>
        </Move9jaCard>
        <Move9jaTravelTimeline />

        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 2,
          }}
        >
          Any issue with travel?{" "}
          <span
            style={{
              color: "#D10121",
              textDecoration: "underline",
              fontWeight: "bold",
              cursor: "pointer",
              marginLeft: "3px",
            }}
            role='button'
          >
            Report a problem
          </span>{" "}
        </Typography>
      </Box>
    </DashboardLayout>
  );
};

export default TravelDetails;
