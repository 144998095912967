import ArrowBack from "@mui/icons-material/ArrowBack";
import { Divider, Radio, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ProfileLayout } from "layouts/ProfileLayout";
import React from "react";
import { useNavigate } from "react-router-dom";

const Preferences = () => {
  const navigate = useNavigate();
  return (
    <ProfileLayout>
      <Box height="100vh">
        <Box p={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography fontWeight={700} fontSize={20}>
                Preferences
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box p={2}>
          <Typography fontSize={12}>Delivery</Typography>
          <ReUseableComponent
            heading="Auto Accept Delivery"
            subHeading="Automatically accept ride ion"
          />
          <Typography fontSize={12} mt={3}>
            Notifications
          </Typography>
          <ReUseableComponent
            heading="Push Notifications"
            subHeading="Send push notification when I have an activity"
          />
          <ReUseableComponent
            heading="Emails"
            subHeading="Send notification to my email"
          />
          <ReUseableComponent
            heading="Promos and Updates"
            subHeading="Send marketing updates to my emails"
          />
          <Typography fontSize={12} mt={3}>
            2-Factor Authentication
          </Typography>
          <div>
            <Box display="flex" alignItems="flex-end" py="12px">
              <Radio />
              <div>
                <Typography fontSize={14} fontWeight={600}>
                  Email
                </Typography>
                <Typography fontSize={12}>
                  Always send OTP code whrn I try to login or to do tra..
                </Typography>
              </div>
            </Box>
            <Divider />
          </div>
          <div>
            <Box display="flex" alignItems="flex-end" py="12px">
              <Radio />
              <div>
                <Typography fontSize={14} fontWeight={600}>
                  SMS
                </Typography>
                <Typography fontSize={12}>
                  Always send OTP code whrn I try to login or to do tra..
                </Typography>
              </div>
            </Box>
            <Divider />
          </div>
        </Box>
      </Box>
    </ProfileLayout>
  );
};

export default Preferences;

const ReUseableComponent = ({
  heading,
  subHeading,
}: {
  heading: string;
  subHeading: string;
}) => {
  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        py="12px"
      >
        <div>
          <Typography fontSize={14} fontWeight={600}>
            {heading}
          </Typography>
          <Typography fontSize={12}>{subHeading}</Typography>
        </div>
        <Switch />
      </Box>
      <Divider />
    </div>
  );
};
