import {
  Box,
  Button,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DashboardLayout from 'layouts/DashboardLayout';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';

import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { handleMutationError } from 'utils/handler';
import { postAdon } from 'services/addon';

// Define Yup validation schema
const validationSchema = Yup.object().shape({
  description: Yup.string().required('Description is required'),
  name: Yup.string().required('Name is required'),
  price: Yup.number()
    .required('Price is required')
    .typeError('Price must be a number')
    .positive('Price must be a positive number'),
});

// Define the type for the form data
interface FormData {
  description: string;
  price: number;
  name: string;
}

const AddOn = () => {
  // Initialize React Hook Form with Yup validation
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  });

  const mutation = useMutation({
    mutationFn: postAdon,
    onSuccess: ({ data }) => {
      toast.success('Saved successfully');
    },
    onError: (err) => {
      handleMutationError(err);
    },
  });

  const navigate = useNavigate();

  // Function to handle form submission
  const onSubmit = (data: FormData) => {
    mutation.mutate(data);
  };

  return (
    <DashboardLayout>
      <Box p={2} component='form' onSubmit={handleSubmit(onSubmit)}>
        {/* Header */}
        <Box display='flex' pt={4} alignItems='center' mb={4}>
          <IconButton
            onClick={() => {
              navigate('/dashboard');
            }}
          >
            <ArrowBackIcon fontSize='small' />
          </IconButton>
          <Typography variant='h6' fontWeight='bold' ml={1}>
            Add Add-on
          </Typography>
        </Box>

        {/* Description Field */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <Typography fontSize={12} fontWeight={600} mb={1}>
            Name
          </Typography>
          <Controller
            name='name'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextField
                {...field}
                variant='standard'
                sx={{ borderRadius: '8px', bgcolor: '#F9F9F9', p: 1 }}
                placeholder='Kindly put in a name'
                error={!!errors.description}
                helperText={errors.description?.message}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <Typography fontSize={12} fontWeight={600} mb={1}>
            Description
          </Typography>
          <Controller
            name='description'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextField
                {...field}
                variant='standard'
                sx={{ borderRadius: '8px', bgcolor: '#F9F9F9', p: 1 }}
                placeholder='Description'
                error={!!errors.description}
                helperText={errors.description?.message}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            )}
          />
        </FormControl>

        {/* Price Field */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <Typography fontSize={12} fontWeight={600} mb={1}>
            Price
          </Typography>
          <Controller
            name='price'
            control={control}
            //@ts-ignore
            defaultValue=''
            render={({ field }) => (
              <TextField
                {...field}
                variant='standard'
                sx={{ borderRadius: '8px', bgcolor: '#F9F9F9', p: 1 }}
                placeholder='Price'
                type='number'
                error={!!errors.price}
                helperText={errors.price?.message}
                InputProps={{
                  startAdornment: <Typography>#</Typography>,
                  disableUnderline: true,
                }}
              />
            )}
          />
        </FormControl>

        <Button
          disabled={mutation.isPending}
          type='submit'
          variant='contained'
          sx={{ width: '100%', mt: 3 }}
          color='primary'
        >
          {mutation.isPending ? 'Loading...' : 'Save'}
        </Button>
      </Box>
    </DashboardLayout>
  );
};

export default AddOn;
