import { useQuery } from '@tanstack/react-query';
import { getUserProfile } from '../services/auth';

const useGetUser = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['GET_USER_PROFILE'],
    queryFn: getUserProfile,
  });

  return { isLoading, data: data?.data?.profileDetails };
};

export default useGetUser;
