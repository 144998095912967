import { Avatar, Box, Typography } from '@mui/material';

interface PassengersProps {
  name?: string;
  phone?: string;
  seatNumber?: string;
  payMtd?: string;
  amount?: string;
}

const TravelerDetails = ({
  name,
  phone,
  seatNumber,
  payMtd,
  amount,
}: PassengersProps) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      py={1}
      justifyContent='space-between'
      borderBottom='1px solid #F2F4F7'
    >
      <Box display='flex' alignItems='center'>
        <Avatar sx={{ bgcolor: '#EE46BC', mr: 1 }}>EO</Avatar>
        <Box>
          <Typography fontWeight={600} fontSize='12px' color='#101828'>
            {name}
          </Typography>
          <Typography fontSize='12px' color='#98A2B3'>
            {payMtd} - Seat {seatNumber}
          </Typography>
        </Box>
      </Box>
      <Box>
        {/* <Typography fontWeight={600} fontSize="12px" color="#000000">
          NGN {amount}
        </Typography> */}
        <Typography fontSize='12px' fontWeight={400} color='#379E66'>
          Completed
        </Typography>
      </Box>
    </Box>
  );
};

export default TravelerDetails;
