import { Add, FoodBank, NavigateNext } from '@mui/icons-material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import gtbank from 'assets/gtbank.svg';
import { Button, Tooltip } from '@mui/material';
import { ProfileLayout } from 'layouts/ProfileLayout';
import { useQuery } from '@tanstack/react-query';
import { getParkDetails } from 'services/auth';

const Payouts = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery({
    queryKey: ['PARK_DETAILS'],
    queryFn: getParkDetails,
  });

  const bankDetails = data?.data?.detials;
  return (
    <ProfileLayout>
      <Box height='100vh'>
        <Box p={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography fontWeight={700} fontSize={20}>
                Payouts
              </Typography>
            </Box>
          </Box>
          <Box mt={3}>
            {[1].map((_) => (
              <Box
                border='1px solid #F2F4F7'
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                p='16px'
                mb='12px'
                borderRadius='8px'
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '8px',
                    width: '100%',
                  }}
                >
                  {/* <img src={gtbank} alt='' height={26} width={26} /> */}
                  <div style={{ width: '100%' }}>
                    <Box
                      display='flex'
                      alignItems='center'
                      width='100%'
                      justifyContent='space-between'
                    >
                      <Typography fontWeight={600} fontSize={12}>
                        {bankDetails?.account_name}
                      </Typography>
                      <Typography fontWeight='bold'>
                        {bankDetails?.bank_name}
                      </Typography>
                    </Box>
                    <Typography fontSize={12} mt={1}>
                      {bankDetails?.account_number}
                    </Typography>
                  </div>
                </div>
              </Box>
            ))}
          </Box>
          <Tooltip title='Coming soon' arrow>
            <Button
              sx={{ p: '12px 24px', color: 'white' }}
              variant='contained'
              fullWidth
              disabled
            >
              <Add /> Add bank
            </Button>
          </Tooltip>
        </Box>
      </Box>
    </ProfileLayout>
  );
};

export default Payouts;
